import type { ValidationErrors } from 'final-form';
import { FormSpy } from 'react-final-form';

import { Translate } from '@change-corgi/core/react/i18n';
import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { InlineMessage } from '@change-corgi/design-system/components/alerts';

import { useFormI18n } from 'src/app/shared/form/hooks';

type Props = Readonly<{ errors: ValidationErrors }>;

const InvalidFormErrorMessage: React.FC<Props> = ({ errors }) => {
	const { getErrorMessage } = useFormI18n();

	if (!errors) return null;

	return (
		/*
		 * Hiding the list of errors for sighted users as they can see those errors in the form already.
		 */
		<VisuallyHidden>
			<InlineMessage size="large" role="alert" variant="error">
				<Translate value="corgi.components.form.invalid.alert" />
				<ul>
					{Object.entries(errors).map(([name, error]) => (
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						<li key={name}>{getErrorMessage(error)}</li>
					))}
				</ul>
			</InlineMessage>
		</VisuallyHidden>
	);
};

export const InvalidFormError: React.FC = () => {
	return (
		<FormSpy
			subscription={{
				hasValidationErrors: true,
				errors: true,
				modifiedSinceLastSubmit: true,
				submitFailed: true,
			}}
		>
			{({ hasValidationErrors, errors, submitFailed, modifiedSinceLastSubmit }) =>
				/*
				 * this results in the following behavior:
				 * - message is not visible unless form has been submitted at least once
				 * - message is not visible unless there are validation errors in the form
				 * - message is hidden as soon as any field is modified
				 * - message will reappear once the form is re-submitted and there are still validation errors
				 */
				hasValidationErrors && submitFailed && !modifiedSinceLastSubmit ? (
					<InvalidFormErrorMessage errors={errors} />
				) : null
			}
		</FormSpy>
	);
};
