import { Field } from 'react-final-form';

import { translationKey } from '@change-corgi/core/i18n';
import { useI18n } from '@change-corgi/core/react/i18n';
import { Select } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import { useFormI18n } from 'src/app/shared/form/hooks';
import { requiredValidator } from 'src/app/shared/form/validators';

import type { FormValues, ReportType, SubCategory } from '../../shared/types';

import { useSubCategoryField } from './hooks/useSubCategoryField';

type Props = Readonly<{
	type: ReportType;
}>;

const validator = requiredValidator<SubCategory>({
	i18n: { key: translationKey('corgi.policy_abuse.generic.errors.sub_category_required') },
});

export const SubCategoryField: React.FC<Props> = ({ type }) => {
	const { translate } = useI18n();
	const { getErrorMessage } = useFormI18n();

	const {
		data: { subCategories },
		actions: { getLabel },
	} = useSubCategoryField({ type });

	if (subCategories.length === 0) return null;

	return (
		<Box mb={8}>
			<Field<FormValues['subCategory']> name="subCategory" validate={validator}>
				{({ input, meta }) => (
					<Select
						name={input.name}
						value={input.value}
						onChange={input.onChange}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						error={meta.touched && getErrorMessage(meta.error)}
						data-testid="reportAbuse-subCategory-select"
						data-qa="reportAbuse-subCategory-select"
						label={translate('corgi.policy_abuse.generic.sub_category.label')}
					>
						<option value="">{translate('corgi.policy_abuse.generic.sub_category.prompt')}</option>
						{subCategories.map((subCategory) => (
							<option value={subCategory} key={subCategory}>
								{getLabel(subCategory)}
							</option>
						))}
					</Select>
				)}
			</Field>
		</Box>
	);
};
