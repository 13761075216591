import { getMediaAssetsBasePath } from '@change-corgi/config/mediaAssets';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

export const useMediaAssetsBasePath = ({ mode }: { mode: 'edit' | 'view' }): string => {
	const {
		environment: { getApiEnvironment },
	} = useUtilityContext();
	const apiEnvironment = getApiEnvironment();
	const environment = apiEnvironment !== 'development' ? apiEnvironment : 'demo';
	return getMediaAssetsBasePath({ environment, mode });
};
