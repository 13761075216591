import { useMemo } from 'react';

import { translationKey } from '@change-corgi/core/i18n';

import type { I18nFieldValidator } from 'src/app/shared/form/validators';
import { requiredValidator } from 'src/app/shared/form/validators';

import { useQuestionLabel } from '../../../../../../../../hooks/useQuestionLabel';
import { useQuestionOptionsLabel } from '../../../../../../../../hooks/useQuestionOptionsLabel';
import type { FieldType, OptionsQuestion, ReportType } from '../../../../../../../../shared/types';

type Result = ModelHookResult<
	{
		fieldName: string;
		questionLabel: string;
		validator: I18nFieldValidator<string | undefined> | undefined;
	},
	{
		getQuestionOptionsLabel: ReturnType<typeof useQuestionOptionsLabel>['actions']['getQuestionOptionsLabel'];
	}
>;

type Props = {
	type: ReportType;
	parent: FieldType;
	question: OptionsQuestion;
	idx: number;
};
export function useOptionsQuestionField({ type, parent, question, idx }: Props): Result {
	const {
		data: { questionLabel },
	} = useQuestionLabel(type, parent, question.name);
	const {
		actions: { getQuestionOptionsLabel },
	} = useQuestionOptionsLabel(type, parent, question.name);

	const validator = useMemo(() => {
		if (question.required)
			return requiredValidator({
				i18n: { key: translationKey('corgi.policy_abuse.generic.errors.question.required') },
			});
		return undefined;
	}, [question.required]);

	// This is used by the form to know which property we're accessing
	const fieldName = `question${idx}`;
	return {
		data: {
			fieldName,
			questionLabel,
			validator,
		},
		actions: {
			getQuestionOptionsLabel,
		},
	};
}
