import { getCountryInfo } from '@change-corgi/config/countries';

import type {
	Category,
	FormValues,
	NormalizedFormValues,
	PetitionReport,
	PetitionReportContext,
	Report,
	ReportContext,
	ReportType,
	SubCategory,
	SubSubCategory,
} from '../../shared/types';

type CategoryLabels = Record<Category, string>;
type SubCategoryLabels = Record<SubCategory, string>;
type SubSubCategoryLabels = Record<SubSubCategory, string>;

const CATEGORY_API_LABELS: Readonly<Record<ReportType, Readonly<CategoryLabels>>> = {
	petition: {
		violates_guidelines: 'The content violates Change.org’s Community Guidelines',
		illegal_content: 'The content is illegal',
		dislike_content: 'I dislike or disagree with this petition',
	},
};

const SUB_CATEGORY_API_LABELS: Readonly<Record<ReportType, Readonly<SubCategoryLabels>>> = {
	petition: {
		bullying_harassment: 'Bullying or Harassment',
		hate_discrimination: 'Hate or Discrimination',
		defamation_libel: 'Defamation or Libel',
		violence: 'Violence',
		selfharm_suicide: 'Self-Harm or Suicide',
		graphic_imagery: 'Graphic or Disturbing Imagery',
		privacy_violations: 'Privacy Violations (Adult)',
		minors_protection: 'Protection of Minors',
		pornography: 'Pornography or Sexualized Content',
		misleading_information: 'False or Misleading Information',
		deception_fraud: 'Deception or Fraud',
		excessive_edits: 'Excessive Petition Edits',
		spam: 'Spam and Spam-Like Behavior',
		intellectual_property: 'Intellectual Property Infringement',
		other_illegal: 'Other Illegal Content or Activity',
	},
};

const SUB_SUB_CATEGORY_API_LABELS: Readonly<Record<ReportType, Readonly<SubSubCategoryLabels>>> = {
	petition: {
		bullying: 'Petition is intended for bullying or harassing another person or persons',
		vexatious:
			'Petition is vexatious (i.e. contains frivolous or irrelevant claims, deliberate attempts to provoke or incite conflict and/or trolling behavior)',
		hate_speech: 'Petition contains hate speech and/or is discriminatory',
		hate_group:
			'Petition contains content affiliated with hate groups (this may include logos and symbols) and/or may be started by someone associated with a hate group',
		pii_adult: 'The petition contains personally identifiable information about an adult',
		pii_minor: 'The petition contains personally identifiable information about a minor',
		impersonation: 'A user profile was created using someone else’s name/identity or email address',
		offensive_username: 'An account’s username is intentionally offensive or abusive',
		fraudulent_link: 'Petition links to a fraudulent website or unverified fundraiser',
	},
};

export function getCategoryApiLabel(type: ReportType, category: Category): string {
	return CATEGORY_API_LABELS[type][category];
}

export function getSubCategoryApiLabel(type: ReportType, subCategory: SubCategory): string {
	return SUB_CATEGORY_API_LABELS[type][subCategory];
}

export function getSubSubCategoryApiLabel(type: ReportType, subSubCategory: SubSubCategory): string {
	return SUB_SUB_CATEGORY_API_LABELS[type][subSubCategory];
}

export function normalizeFormValues(type: ReportType, formValues: FormValues): NormalizedFormValues {
	const country = formValues.country ? getCountryInfo(formValues.country) : undefined;

	return {
		country: country ? country.display : '',
		category: formValues.category ? getCategoryApiLabel(type, formValues.category) : '',
		subCategory: formValues.subCategory ? getSubCategoryApiLabel(type, formValues.subCategory) : '',
		subSubCategory: formValues.subSubCategory ? getSubSubCategoryApiLabel(type, formValues.subSubCategory) : '',
		question1: formValues.question1 ? formValues.question1 : '',
		question2: formValues.question2 ? formValues.question2 : '',
		question3: formValues.question3 ? formValues.question3 : '',
		question4: formValues.question4 ? formValues.question4 : '',
	};
}

const petitionPayload = (reportContext: PetitionReportContext): PetitionReport => ({
	petitionId: reportContext.petitionId,
	petitionUrl: `/p/${reportContext.petitionSlug}`,
});

export function normalizePayload(reportContext: ReportContext): Report {
	switch (reportContext.type) {
		case 'petition':
			return petitionPayload(reportContext);
		default:
			throw new Error('Unsupported report context type');
	}
}
