import { useCallback, useState } from 'react';

import isEmail from 'validator/lib/isEmail';

import { useI18n } from '@change-corgi/core/react/i18n';
import { useTracking } from '@change-corgi/core/react/tracking';

import type { SessionUser } from 'src/shared/session';

import { isLoading } from 'src/app/shared/utils/async';

import type { FormState } from '../../shared/types';

import { useLoginByEmail } from './useLoginByEmail';
import { useSendResetPasswordLink } from './useSendResetPasswordLink';

type Result = ModelHookResult<
	{
		userInfo?: {
			avatarUrl: string;
			displayName: string;
			location: string;
		};
		email: string;
		password: string;
		formState: FormState | undefined;
		resetPasswordState: 'loading' | 'error' | 'success' | undefined;
	},
	{
		setPassword: (newPassword: string) => void;
		setEmail: (newEmail: string) => void;
		attemptLogin: () => Promise<void>;
		sendResetPasswordLink: () => Promise<void>;
	}
>;

function useUserInfo(user?: SessionUser): Result['data']['userInfo'] {
	const { getCountry } = useI18n();

	if (!user) return undefined;
	return {
		avatarUrl: user.photo?.userMedium.url || 'https://static.change.org/profile-img/default-user-profile.svg',
		displayName: user.displayName,
		location: [user.city, user.stateCode, user.country ? getCountry(user.country?.countryCode)?.name : undefined]
			.filter((l): l is string => !!l)
			.join(', '),
	};
}

type Params = {
	user?: SessionUser;
	email: string;
	onSuccess: (user: SessionUser) => void;
};

type AttemptLoginParams = Params & { canAttemptLogin: boolean; password: string };

function useAttemptLogin({ email, onSuccess, canAttemptLogin, password }: AttemptLoginParams) {
	const [formState, setFormState] = useState<FormState | undefined>(undefined);
	const loginByEmail = useLoginByEmail();
	const { translate } = useI18n();
	const track = useTracking();

	return {
		formState,
		attemptLogin: useCallback(async () => {
			void track('email_login_button_click');
			void track('login_or_join_attempt', {
				context: 'log_in',
				user_flow: 'payment',
				login_or_join_method: 'email_pswd',
			});
			if (!password || !isEmail(email)) {
				setFormState({
					status: 'error',
					email: isEmail(email) ? '' : translate('fe.errors.user.email.presence'),
					password: password ? '' : translate('fe.errors.user.password.presence'),
				});
			}
			if (!canAttemptLogin || (formState && isLoading(formState))) {
				return;
			}
			setFormState({ status: 'loading' });
			try {
				const newUser = await loginByEmail({ email, password });
				setFormState(undefined);
				onSuccess(newUser);
				void track('successful_login_or_join', {
					context: 'log_in',
					id: `user_${newUser.id}`,
					login_or_join_method: 'email_pswd',
					login_type: 'email_login',
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'new_user_this_session?': false,
					user_flow: 'payment',
					user_id: parseInt(newUser.id, 10),
				});
			} catch (e) {
				void track('error', { type: 'user_login', response: (e as Error).message });
				setFormState({ status: 'error', password: (e as Error).message });
			}
		}, [loginByEmail, canAttemptLogin, email, password, formState, onSuccess, translate, track]),
	};
}

export function useLoginModalForm(params: Params): Result {
	const { user, email: emailParam } = params;

	const [{ email, password }, setFormData] = useState<{ email: string; password: string }>({
		email: emailParam,
		password: '',
	});
	const [resetPasswordState, setResetPasswordState] = useState<'loading' | 'error' | 'success' | undefined>(undefined);
	const sendResetPasswordLink = useSendResetPasswordLink(email);
	const userInfo = useUserInfo(user);
	const canAttemptLogin = !!password && !!email;
	const { attemptLogin, formState } = useAttemptLogin({ ...params, canAttemptLogin, password, email });
	const track = useTracking();

	return {
		data: { userInfo, email, password, formState, resetPasswordState },
		actions: {
			setPassword: useCallback((newPassword: string) => setFormData({ email, password: newPassword }), [email]),
			setEmail: useCallback((newEmail: string) => setFormData({ email: newEmail, password }), [password]),
			attemptLogin,
			sendResetPasswordLink: useCallback(async () => {
				if (resetPasswordState === 'loading') return;
				setResetPasswordState('loading');
				void track('password_reset');
				const success = await sendResetPasswordLink();
				setResetPasswordState(success ? 'success' : 'error');
			}, [resetPasswordState, sendResetPasswordLink, track]),
		},
	};
}
