import type { Decorator, FormApi } from 'final-form';

import type { FormValues } from '../types';

export const resetFormDecorator: Decorator = (form: FormApi<FormValues>) => {
	let previousValues: Partial<FormValues> = form.getState().initialValues || {};
	const unsubscribe = form.subscribe(
		({ values }) => {
			if (values.category !== previousValues.category) {
				form.batch(() => {
					form.restart();
					form.change('category', values.category);
					// Country is not supposed to be reset by other changes
					form.change('country', values.country);
				});
			} else if (values.subCategory !== previousValues.subCategory) {
				form.batch(() => {
					form.restart();
					form.change('category', values.category);
					form.change('subCategory', values.subCategory);
					// Country is not supposed to be reset by other changes
					form.change('country', values.country);
				});
			} else if (values.subSubCategory !== previousValues.subSubCategory) {
				form.batch(() => {
					form.restart();
					form.change('category', values.category);
					form.change('subCategory', values.subCategory);
					form.change('subSubCategory', values.subSubCategory);
					// Country is not supposed to be reset by other changes
					form.change('country', values.country);
				});
			}
			previousValues = values;
		},
		{ values: true },
	);
	return unsubscribe;
};
