import type { Session, SessionUser } from 'src/shared/session';

import type { CookiePrefsRequest } from '../types';

import type { SessionState } from './types';

export const mutations = {
	setCookiePrefs({ session, sessionUpdates }: SessionState, prefs: CookiePrefsRequest): SessionState {
		return {
			session: session && {
				...session,
				cookiePrefs: Object.entries(prefs).reduce<Session['cookiePrefs']>(
					(acc, [name, { status }]) => ({
						...acc,
						[name]: status,
					}),
					{ analytics: false, preferences: false, marketing: false },
				),
				cookiePrefsSaved: true,
			},
			sessionUpdates: sessionUpdates + 1,
		};
	},
	setUser(
		{ session, sessionUpdates }: SessionState,
		{
			loginState,
			user,
			uuid,
			hasMembership,
		}: { uuid: string; user: SessionUser; loginState: Exclude<Session['loginState'], 'GUEST'>; hasMembership: boolean },
	): SessionState {
		return {
			session: session && {
				...session,
				uuid,
				user,
				loginState,
				hasMembership,
			},
			sessionUpdates: sessionUpdates + 1,
		};
	},
	clearUser({ session, sessionUpdates }: SessionState, { uuid }: { uuid: string }): SessionState {
		return {
			session: session && {
				...session,
				uuid,
				user: null,
				loginState: 'GUEST',
			},
			sessionUpdates: sessionUpdates + 1,
		};
	},
};
