import type { I18nCountryInfo } from '@change-corgi/core/i18n';
import { useI18n } from '@change-corgi/core/react/i18n';

import { useCurrentAuthenticatedUser } from 'src/app/shared/hooks/session';

type Result = ModelHookResult<{
	countries: readonly I18nCountryInfo[];
	initialValue: string | undefined;
}>;

export function useCountryField(): Result {
	const { getCountries } = useI18n();
	const userCountryCode = useCurrentAuthenticatedUser()?.country?.countryCode;

	return {
		data: {
			countries: getCountries(),
			initialValue: userCountryCode,
		},
	};
}
