import { useCallback } from 'react';

import qs from 'qs';

import { useKnownAvailableParams } from '@change-corgi/core/react/router';

import { useStarterOnboardingPageData } from 'src/app/pages/starterOnboarding/pageContext';
import { useCurrentUserId, useSessionUuid } from 'src/app/shared/hooks/session';
import type { ShareActionsOptions, ShareChannel } from 'src/app/shared/hooks/share';

const MEDIUM_MAPPING: Readonly<Partial<Record<ShareChannel, string>>> = {
	facebookMessenger: 'facebook_messenger',
	copy: 'copylink',
};

function getPetitionShareUrl({
	sessionUuid,
	currentUserId,
	slug,
	utmSource,
	channel,
}: {
	sessionUuid: string;
	currentUserId: string | undefined;
	slug: string;
	utmSource: string;
	channel: ShareChannel;
}) {
	const trackParams = qs.stringify(
		{
			recruiter: currentUserId || undefined,
			recruited_by_id: sessionUuid,
			utm_source: utmSource,
			utm_campaign: 'starter_onboarding_share_flow',
			utm_medium: MEDIUM_MAPPING[channel] || channel || 'default', // 'default' is used for the default case
		},
		{ encodeValuesOnly: true },
	);
	if (currentUserId) return `/p/${slug}/sfs/${channel}/${currentUserId}?${trackParams}`;

	return `/p/${slug}?${trackParams}`;
}

export function useGetStarterOnboardingShareUrl(): ShareActionsOptions<ShareChannel>['getUrl'] {
	const sessionUuid = useSessionUuid();
	const currentUserId = useCurrentUserId();
	const { step } = useKnownAvailableParams<{ step: string }>();
	const {
		data: { petition },
	} = useStarterOnboardingPageData();

	return useCallback(
		(channel) => {
			const commonUrlArgs = { sessionUuid, currentUserId, slug: petition.slug, utmSource: step, channel };

			return getPetitionShareUrl({ ...commonUrlArgs });
		},
		[currentUserId, petition.slug, sessionUuid, step],
	);
}
