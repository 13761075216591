import { Translate } from '@change-corgi/core/react/i18n';
import { createModalHook } from '@change-corgi/design-system/modals';
import { Heading } from '@change-corgi/design-system/typography';

import type { SessionUser } from 'src/shared/session';

import { LoginModalBody } from './components/LoginModalBody';

export const useLoginModal = createModalHook<
	{ user?: SessionUser; googleAuthEnabled: boolean; showContinueAsGuest: boolean; email: string },
	{ user: SessionUser | undefined }
>({
	name: 'PromotePageLoginModal',
	stylesOverrides: {
		width: 420,
	},
	heading: function PromotePageLoginModalHeading({ initialFocusAttributes }) {
		// This is to match fe's UI (big centered title)
		return (
			<Heading as="h1" size={['subtitle', 'h3']} sx={{ textAlign: 'center' }} mb={16} {...initialFocusAttributes}>
				<Translate value="fe.components.login_or_join.log_in" />
			</Heading>
		);
	},
	body: function PromotePageLoginModalBody({ options, closeModal }) {
		return (
			<LoginModalBody
				user={options.user}
				email={options.email}
				showContinueAsGuest={options.showContinueAsGuest}
				googleAuthEnabled={options.googleAuthEnabled}
				onSuccess={(user) => closeModal({ user })}
				onContinueAsGuest={() => closeModal({ user: undefined })}
			/>
		);
	},
});
