import { useMemo } from 'react';

import type { ShareActionsOptions, ShareChannel } from 'src/app/shared/hooks/share';

import { useGetStarterOnboardingShareUrl } from './useGetPetitionShareUrl';
import { useGetStarterOnboardingShareText } from './useGetStarterOnboardingShareText';
import { useGetStarterOnboardingShareTrackingData } from './useGetStarterOnboardingShareTrackingData';

export function useStarterOnboardingShareOptions(): ShareActionsOptions<ShareChannel> {
	const getUrl = useGetStarterOnboardingShareUrl();
	const getText = useGetStarterOnboardingShareText();
	const getTrackingData = useGetStarterOnboardingShareTrackingData();

	return useMemo(
		() => ({
			trackingEventName: 'share_petition',
			getUrl,
			getText,
			getTrackingData,
		}),
		[getUrl, getText, getTrackingData],
	);
}
