import { useStarterOnboardingPageData, useStarterOnboardingPageFcm } from 'src/app/pages/starterOnboarding/pageContext';
import type { OnlineShareChannel } from 'src/app/pages/starterOnboarding/shared/types';

export function useStarterOnboardingShareChannels(): OnlineShareChannel[] {
	const { globalShareButtonConfig } = useStarterOnboardingPageFcm();
	const {
		data: {
			petition: { geoReach },
		},
	} = useStarterOnboardingPageData();

	if (globalShareButtonConfig.includes('nextdoor') && geoReach !== 'local') {
		return globalShareButtonConfig.filter((ch) => ch !== 'nextdoor');
	}

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return globalShareButtonConfig as OnlineShareChannel[];
}
