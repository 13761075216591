import type { JSX, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import type { Session } from 'src/shared/session';

import { SessionContextProvider } from './context';
import { useSessionMutations } from './set/mutations';
import { SessionStateContextProvider } from './stateContext';

type Props = {
	session?: Session;
};

function SessionAutoLoginSimulator({
	initialSession,
	children,
}: PropsWithChildren<{ initialSession: Session | undefined }>) {
	const { setUser } = useSessionMutations();

	useEffect(() => {
		if (initialSession && initialSession.user) {
			window.setTimeout(
				() => {
					setUser({
						user: initialSession.user,
						loginState: initialSession.loginState,
						uuid: initialSession.uuid,
						hasMembership: initialSession.hasMembership,
					});
				},
				parseInt(process.env.SIMULATE_AUTO_LOGIN_DELAY || '5000', 10),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <>{children}</>;
}

export const SessionProvider: (props: PropsWithChildren<Props>) => JSX.Element = process.env.SIMULATE_AUTO_LOGIN
	? function SessionProvider({ session: sessionProp, children }) {
			const [session] = useState<Session | undefined>(
				sessionProp ? { ...sessionProp, loginState: 'GUEST', user: null } : sessionProp,
			);

			return (
				<SessionStateContextProvider session={session}>
					<SessionContextProvider>
						<SessionAutoLoginSimulator initialSession={sessionProp}>{children}</SessionAutoLoginSimulator>
					</SessionContextProvider>
				</SessionStateContextProvider>
			);
		}
	: function SessionProvider({ session, children }) {
			return (
				<SessionStateContextProvider session={session}>
					<SessionContextProvider>{children}</SessionContextProvider>
				</SessionStateContextProvider>
			);
		};
