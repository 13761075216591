/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createMandatoryContext } from '@change-corgi/core/react/context';

import { createFcmExperimentsFunctions } from 'src/app/shared/hooks/experiment';
import type {
	FcmExperimentConfig,
	FcmExperimentsFunctionsOptions,
	FcmExperimentsFunctionsResult,
	FcmExperimentsFunctionsReturnValue,
} from 'src/app/shared/hooks/experiment';

type Options<
	T extends Record<string, FcmExperimentConfig>,
	P extends boolean,
	DATA_PROPERTY extends string = 'fcmExperiments',
> = {
	/**
	 * name of the context, to be used for the Provider's display name
	 * and any error being possibly thrown
	 *
	 * e.g. "MyFcmContext"
	 */
	name: string;
	/**
	 * Name of the property that will contain the FCM values in the returned objects + name of the prop in the provider
	 *
	 * Default is "fcm"
	 */
	dataProperty?: DATA_PROPERTY;
	/**
	 * FCM Experiments configs (from @change-corgi/config/fcm/configs)
	 */
	configs: T;
} & Omit<FcmExperimentsFunctionsOptions<true, P, false, DATA_PROPERTY>, 'hook' | 'getter' | 'valueProperty'>;

type ContextFactoryResult<
	T extends Record<string, FcmExperimentConfig>,
	DATA_PROPERTY extends string = 'fcmExperiments',
> = ReturnType<
	typeof createMandatoryContext<
		FcmExperimentsFunctionsResult<T>,
		{
			[key in DATA_PROPERTY]: FcmExperimentsFunctionsResult<T>;
		}
	>
>;

type Result<
	T extends Record<string, FcmExperimentConfig>,
	P extends boolean,
	DATA_PROPERTY extends string = 'fcmExperiments',
> = {
	/**
	 * The context that will hold the synchronous FCM Experiment data
	 */
	FcmExperimentsContext: ContextFactoryResult<T, DATA_PROPERTY>['Context'];
	/**
	 * The provider that will expect the synchronous FCM Experiment data
	 */
	FcmExperimentsProvider: ContextFactoryResult<T, DATA_PROPERTY>['Provider'];
	/**
	 * The synchronous hook based on the context
	 */
	useFcmExperiments: ContextFactoryResult<T, DATA_PROPERTY>['useContext'];
	/**
	 * The asynchronous hook that will hold the data that should be passed to the provider
	 */
	useAsyncFcmExperiments: FcmExperimentsFunctionsReturnValue<T, true, P, false, DATA_PROPERTY>['useFcmExperiments'];
} & Omit<FcmExperimentsFunctionsReturnValue<T, true, P, false, DATA_PROPERTY>, 'useFcmExperiments'>;

export function createFcmExperimentsContext<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	T extends Record<string, FcmExperimentConfig<any, any>>,
	P extends boolean,
	DATA_PROPERTY extends string = 'fcmExperiments',
>({
	name,
	dataProperty: dataPropertyProp,
	configs,
	...rest
}: Options<T, P, DATA_PROPERTY>): Result<T, P, DATA_PROPERTY> {
	const dataProperty = dataPropertyProp || 'fcmExperiments';

	const { Context, Provider, useContext } = createMandatoryContext<
		FcmExperimentsFunctionsResult<T>,
		{
			[key in DATA_PROPERTY]: FcmExperimentsFunctionsResult<T>;
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	>(undefined, {
		name,
		processProviderProps: (props: {
			[key in DATA_PROPERTY]: FcmExperimentsFunctionsResult<T>;
		}): FcmExperimentsFunctionsResult<T> => props[dataProperty as DATA_PROPERTY],
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} as any);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const { useFcmExperiments, ...functions } = createFcmExperimentsFunctions(configs, {
		...rest,
		hook: true,
		valueProperty: dataProperty,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	}) as any;

	return {
		FcmExperimentsContext: Context,
		FcmExperimentsProvider: Provider,
		useFcmExperiments: useContext,
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		useAsyncFcmExperiments: useFcmExperiments,
		...functions,
	} as unknown as Result<T, P, DATA_PROPERTY>;
}
