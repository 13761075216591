import { createMandatoryContext } from '@change-corgi/core/react/context';

import type { PageState } from '../../PageState';

export const {
	Context: PageStateContext,
	Provider: PageStateProvider,
	useContext: usePageState,
} = createMandatoryContext<PageState>(undefined, {
	name: 'PageStateContext',
});
