import type { Locale } from '@change-corgi/config/locales';

import intlPluralRulesLocaleMapping from 'config/intl-pluralrules.json';

import { loadMissingFeatures } from '../polyfills';
import type { IntlCheck } from '../types';

export async function loadPolyfill(locale: string, deps: readonly IntlCheck[]): Promise<void> {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const intlLocale: string = intlPluralRulesLocaleMapping.localeMapping[locale as Locale] || locale;
	await loadMissingFeatures(deps, locale);
	await import(/* webpackChunkName: "polyfill/intl-pluralrules/polyfill" */ '@formatjs/intl-pluralrules/polyfill');
	await import(
		/* webpackChunkName: "polyfill/intl-pluralrules/[request]" */ `@formatjs/intl-pluralrules/locale-data/${intlLocale}.js`
	);
}
