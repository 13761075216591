import { useMemo } from 'react';

import type { ShareChannel } from '../shared/types';

import { useFacebookMessengerAvailable } from './channels/facebookMessenger';
import { useInstagramAvailable } from './channels/instagram';
import { useLineAvailable } from './channels/line';
import { useMobileNativeShareAvailable } from './channels/mobileNativeShare';
import { useSmsAvailable } from './channels/sms';
import { useVkAvailable } from './channels/vk';

type BaseShareChannel = ShareChannel; // TODO omit gmail

export function useBaseAvailableShareChannels(): Readonly<Record<BaseShareChannel, boolean>> {
	const vk = useVkAvailable();
	const line = useLineAvailable();
	const facebookMessenger = useFacebookMessengerAvailable();
	const instagram = useInstagramAvailable();
	const sms = useSmsAvailable();
	const mobileNativeShare = useMobileNativeShareAvailable();

	return useMemo(
		() => ({
			facebook: true,
			twitter: true,
			email: true,
			copy: true,
			whatsapp: true,
			nextdoor: true,
			qrcode: true,
			flyer: true,
			instagram,
			vk,
			line,
			facebookMessenger,
			sms,
			mobileNativeShare,
		}),
		[vk, line, facebookMessenger, instagram, sms, mobileNativeShare],
	);
}
