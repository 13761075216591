import React, { useState } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Button } from '@change-corgi/design-system/components/actions';
import { Input } from '@change-corgi/design-system/components/forms';
import { TabPanel, Tabs } from '@change-corgi/design-system/components/navigation';
import { Box, CardList } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

type Petition = {
	id: string;
	ask: string;
};

export type FilterablePetitionsGridProps = {
	sections: Array<{
		id: string;
		displayName: string;
		petitions: readonly Petition[];
		hasNextPage: boolean;
		loadMore: () => void;
	}>;
};

export const FilterablePetitionsGrid: React.FC<FilterablePetitionsGridProps> = ({ sections }) => {
	const [selectedTab, setSelectedTab] = useState(sections[0].id);

	return (
		<Tabs selected={selectedTab} px={[16, 16, 0]} variant="default">
			<Box
				backgroundColor="primary-greyBackground"
				padding="8px"
				marginBottom="26px"
				sx={{
					borderRadius: '8px',
					display: 'flex',
					alignItems: 'center',
					position: 'relative',
					flexDirection: ['column', 'row'],
				}}
			>
				<Box
					sx={{
						display: 'flex',
						gap: '16px',
						flex: 1,
						overflowX: 'auto',
						scrollbarWidth: 'none',
						width: ['100%', 'auto'],
					}}
					role="tablist"
				>
					{sections.map(({ id, displayName }) => {
						const isSelected = id === selectedTab;

						return (
							<Button
								variant="secondary"
								role="tab"
								aria-selected={isSelected}
								key={id}
								sx={{
									borderColor: !isSelected ? 'transparent' : undefined,
									backgroundColor: !isSelected ? 'transparent' : undefined,
									width: 'auto',
									flexShrink: 0,
								}}
								onClick={() => setSelectedTab(id)}
							>
								{displayName}
							</Button>
						);
					})}
				</Box>

				<Box
					sx={{
						width: ['100%', '200px'],
						marginTop: ['8px', '0'],
					}}
				>
					<Input placeholder="Search" aria-label="Search" />
				</Box>
			</Box>

			{sections.map(({ id, petitions, loadMore, hasNextPage }) => (
				<TabPanel name={id} key={id}>
					<CardList itemsPerRow={[1, 2, 3]} itemWidth="dynamic" itemSpacing="small">
						{petitions?.map((petition) => <PetitionCard title={petition.ask} key={petition.id} />)}
					</CardList>

					{hasNextPage && (
						<Button onClick={loadMore} variant="secondary" sx={{ width: '100%', marginTop: '42px' }}>
							<Translate value="fe.components.load_more.load_more" />
						</Button>
					)}
				</TabPanel>
			))}
		</Tabs>
	);
};

// Placeholder Petition Card.
const PetitionCard: React.FC<{ title: string }> = ({ title }) => {
	return (
		<Box>
			<Box
				sx={{ width: 'auto', height: '200px', borderRadius: '12px', marginBottom: '8px' }}
				backgroundColor="#D9D9D9"
			/>
			<Heading size="h5">{title}</Heading>
		</Box>
	);
};
