import pick from 'lodash/pick';
import type { ParsedQs } from 'qs';
import qs from 'qs';
import { CustomError } from 'ts-custom-error';

/**
 * @example
 * throw new RedirectError({ url: '/new-url', permanent: true });
 * throw new RedirectError({ url: '/new-url', reason: 'not_found' });
 * throw new RedirectError({ url: '/new-url', forwardQueryParams: ['source_location'] });
 */
export class RedirectError extends CustomError {
	readonly httpCode: 301 | 307;
	readonly url: string;
	readonly permanent: boolean;
	readonly reason: string | undefined;
	readonly forwardQueryParams?: boolean | readonly string[];

	constructor({
		permanent,
		url,
		reason,
		forwardQueryParams,
		...options
	}: {
		cause?: unknown;
		/**
		 * The url to redirect to
		 */
		url: string;
		/**
		 * Whether to use a 301 or 307 status code
		 */
		permanent?: boolean;
		/**
		 * If present, a redirect_reason will be added to the query params
		 */
		reason?: string;
		/**
		 * If true, all query params will be forwarded from the current url
		 * If an array, only the specified query params will be forwarded
		 */
		forwardQueryParams?: boolean | readonly string[];
	}) {
		super(
			`Redirect Error: ${permanent ? 'permanent' : 'temporary'} redirection to ${url} required ${reason ? `(reason: ${reason})` : ''}`,
			options,
		);
		this.httpCode = permanent ? 301 : 307;
		this.url = url;
		this.permanent = !!permanent;
		this.reason = reason;
		this.forwardQueryParams = forwardQueryParams;
	}
}

export function buildRedirectErrorUrl(error: RedirectError, currentQuery: ParsedQs): string {
	if (!error.forwardQueryParams) return error.url;

	const [main, search] = error.url.split('?');
	const query = {
		...(error.forwardQueryParams === true ? currentQuery : pick(currentQuery, error.forwardQueryParams)),
		...qs.parse(search || ''),
	};
	return `${main}${qs.stringify(query, { addQueryPrefix: true })}`;
}
