import { useCallback } from 'react';

import { translationKey } from '@change-corgi/core/i18n';
import { useI18n } from '@change-corgi/core/react/i18n';

import type { FieldType, ReportType } from '../../shared/types';

type Result = ModelHookResult<
	undefined,
	{
		getQuestionOptionsLabel: (option: string) => string;
	}
>;

// Returns a function that retrieves the associated option given its name
export function useQuestionOptionsLabel(reportType: ReportType, parent: FieldType, name: string): Result {
	const { translate, translationExists } = useI18n();

	return {
		actions: {
			getQuestionOptionsLabel: useCallback(
				(option: string) => {
					const suffix = `question.${parent}.${name}.options.${option}`;

					return translate(
						// Adding reportType so in the future we can handle different
						// labels for the same question depending on the ReportType
						translationExists(`corgi.policy_abuse.${reportType}.${suffix}`)
							? translationKey(`corgi.policy_abuse.${reportType}.${suffix}`)
							: translationKey(`corgi.policy_abuse.generic.${suffix}`),
					);
				},
				[name, parent, reportType, translate, translationExists],
			),
		},
	};
}
