import { useMemo } from 'react';

import { useFormState } from 'react-final-form';

import { useLabel } from '../../../../hooks/useLabel';
import type { FormValues, ReportType, SubCategory } from '../../../../shared/types';
import { getSubCategoriesOrQuestions } from '../../../../shared/utils';

type Result = ModelHookResult<
	{
		subCategories: readonly SubCategory[];
	},
	{
		getLabel: ReturnType<typeof useLabel>['actions']['getLabel'];
	}
>;

export function useSubCategoryField({ type }: { type: ReportType }): Result {
	const {
		values: { category },
	} = useFormState<FormValues>({
		subscription: { values: true },
	});

	const subCategories = useMemo(() => {
		const subCategoriesOrQuestions = category ? getSubCategoriesOrQuestions(type, category) : undefined;
		if (subCategoriesOrQuestions?.type === 'sub_category') return subCategoriesOrQuestions.value;
		return [];
	}, [type, category]);

	const {
		actions: { getLabel },
	} = useLabel(type, 'sub_category');

	return {
		data: {
			subCategories,
		},
		actions: {
			getLabel,
		},
	};
}
