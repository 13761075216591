import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { NormalizedFormValues, Report } from '../../shared/types';

import type { PetitionReportAbuseMutation, PetitionReportAbuseMutationVariables } from './petitionReportAbuse.graphql';
import { PetitionReportAbuse } from './petitionReportAbuse.graphql';

export async function reportAbuse(
	input: NormalizedFormValues & Report,
	{ gql: { fetch } }: UtilityContext,
): Promise<boolean> {
	const { data } = await fetch<PetitionReportAbuseMutation, PetitionReportAbuseMutationVariables>({
		query: PetitionReportAbuse,
		variables: { input },
		rejectOnError: true,
	});

	if (!data || !data.petitionReportAbuse) {
		throw new Error('Mutation returned no data');
	}

	if (data.petitionReportAbuse.__typename === 'ReportAbuseFailure') {
		throw new Error(data.petitionReportAbuse.error);
	}

	return data.petitionReportAbuse.success;
}
