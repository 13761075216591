import { useCallback } from 'react';

import { stripHtmlTags } from '@change-corgi/core/html';
import { useI18n } from '@change-corgi/core/react/i18n';

import { useStarterOnboardingPageData } from 'src/app/pages/starterOnboarding/pageContext';
import { useCurrentUser } from 'src/app/shared/hooks/session';
import type { ShareActionsOptions, ShareChannel } from 'src/app/shared/hooks/share';

// eslint-disable-next-line max-lines-per-function
export function useGetStarterOnboardingShareText(): ShareActionsOptions<ShareChannel>['getText'] {
	const { translate } = useI18n();
	const {
		data: { petition },
	} = useStarterOnboardingPageData();
	const currentUser = useCurrentUser();
	const currentUserName = currentUser?.shortDisplayName || '';
	const descriptionText = stripHtmlTags(petition.description);

	return useCallback(
		(channel, type, { url }) => {
			switch (channel) {
				case 'whatsapp':
					return translate('corgi.petition.starter_onboarding.share_channels.whatsapp.share_text.content_message');
				case 'twitter':
					return translate('corgi.petition.starter_onboarding.share_channels.twitter.share_text.content', {
						petition_title: petition.displayTitle,
					});
				case 'sms':
					return translate('corgi.petition.starter_onboarding.share_channels.sms.share_text.content');
				case 'line':
					return translate('corgi.petition.starter_onboarding.share_channels.line.share_text.content');
				case 'facebookMessenger':
					return translate('corgi.petition.starter_onboarding.share_channels.facebook_messenger.share_text.content');
				case 'email':
					if (type === 'title') return petition.displayTitle;
					return translate('corgi.petition.starter_onboarding.share_channels.email.share_text.content', {
						title: petition.displayTitle,
						number: petition.signatureState.signatureGoal.displayedLocalized,
						url,
						from: currentUserName,
					});
				case 'nextdoor':
					if (descriptionText.length > 900) {
						return translate(
							'corgi.petition.starter_onboarding.share_channels.nextdoor.share_text.content_truncated_body',
							{
								title: petition.displayTitle,
								description: descriptionText.slice(0, descriptionText.lastIndexOf(' ', 900)),
							},
						);
					}
					return translate('corgi.petition.starter_onboarding.share_channels.nextdoor.share_text.content_body', {
						title: petition.displayTitle,
						description: descriptionText,
					});
				default:
					return '';
			}
		},
		[
			translate,
			petition.displayTitle,
			petition.signatureState.signatureGoal.displayedLocalized,
			currentUserName,
			descriptionText,
		],
	);
}
