import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		sampling: {
			type: 'number',
		},
		list: {
			type: 'array',
			optional: true,
			shape: {
				type: 'object',
				shape: {
					operationName: {
						type: 'string',
						optional: true,
					},
					field: {
						type: 'string',
					},
				},
			},
		},
	},
});

export const FCM_GRAPHQL_RESOLVER_TRACING = createFcmConfig('graphql_resolver_tracing', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		sampling: 0,
	},
});
