import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { SessionUser } from 'src/shared/session';

import type { SupporterVoicesConfig } from 'src/app/pages/petition/details/shared/api';
import { getSupporterVoicesConfig } from 'src/app/pages/petition/details/shared/api';

import { isDecisionMaker } from './decisionMaker';
import { isGdprConsentProvided } from './gdprConsentProvided';
import { isPolicyAbuseExperimentEnabled } from './isPolicyAbuseExperimentEnabled';

export type PetitionDetailsPageUserData = {
	isGuest: boolean;
	decisionMaker: boolean;
	gdprConsentProvided: boolean;
	policyAbuseExperimentEnabled: boolean;
} & SupporterVoicesConfig;

export async function getPetitionDetailsPageUserData(
	utilityContext: UtilityContext,
	slugOrId: string,
	{
		decisionMakerHash,
		isGuest,
		sessionUser,
		sessionUUID,
	}: { decisionMakerHash?: string; isGuest: boolean; sessionUser?: SessionUser | null; sessionUUID?: string },
): Promise<PetitionDetailsPageUserData> {
	const [decisionMaker, gdprConsentProvided, supporterVoicesConfig, policyAbuseExperimentEnabled] = await Promise.all([
		!isGuest || decisionMakerHash ? isDecisionMaker(utilityContext, { slugOrId, decisionMakerHash }) : false,
		isGdprConsentProvided(utilityContext),
		getSupporterVoicesConfig(utilityContext, slugOrId, { sessionUser, sessionUUID }),
		isPolicyAbuseExperimentEnabled(utilityContext),
	]);

	return {
		isGuest,
		decisionMaker,
		gdprConsentProvided,
		policyAbuseExperimentEnabled,
		...supporterVoicesConfig,
	};
}
