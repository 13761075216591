import type { Session } from 'src/shared/session';

export const sessionIdOrUserId = (value: Session): string => {
	const { uuid } = value;
	let userId = uuid;
	if (value.user && value.user.id) {
		userId = value.user.id;
	}
	return userId;
};
