import { useCallback } from 'react';

import { FORM_ERROR } from 'final-form';

import { translationKey } from '@change-corgi/core/i18n';

import type { I18nFieldValidatorResult } from 'src/app/shared/form/validators';

import type { FormValues, ReportContext } from '../../shared/types';
import { useReportAbuse } from '../useReportAbuse';
import { useReportAbuseTracking } from '../useReportAbuseTracking';

type Result = ModelHookResult<
	undefined,
	{
		submitReport: (formValues: FormValues) => Promise<Record<string, I18nFieldValidatorResult> | undefined>;
	}
>;

export function usePetitionReportAbuseForm({
	reportContext,
	onSuccess,
}: {
	reportContext: ReportContext;
	onSuccess?: () => void;
}): Result {
	const {
		actions: { trackReportAbuse },
	} = useReportAbuseTracking({ reportContext });

	const {
		actions: { reportAbuse },
	} = useReportAbuse({ reportContext });

	const submitReport = useCallback(
		async (formValues: FormValues) => {
			try {
				const result = await reportAbuse(formValues);
				trackReportAbuse({ success: result });
				onSuccess?.();
				return undefined;
			} catch (e) {
				trackReportAbuse({ success: false });
				const key =
					(e as Error).message === 'UNAUTHORIZED'
						? translationKey('corgi.policy_abuse.generic.errors.unauthorized')
						: translationKey('fe.errors.generic_try_again');
				return {
					[FORM_ERROR]: { key },
				} satisfies Record<string, I18nFieldValidatorResult>;
			}
		},
		[onSuccess, reportAbuse, trackReportAbuse],
	);

	return {
		actions: {
			submitReport,
		},
	};
}
