import React from 'react';

import { Field } from 'react-final-form';

import { translationKey } from '@change-corgi/core/i18n';
import { Radio, RadioGroup } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import { useFormI18n } from 'src/app/shared/form/hooks';
import { requiredValidator } from 'src/app/shared/form/validators';

import type { Category, FormValues, ReportType } from '../../shared/types';

import { useCategoryField } from './hooks/useCategoryField';

type Props = Readonly<{
	type: ReportType;
}>;

const validator = requiredValidator<Category>({
	i18n: { key: translationKey('corgi.policy_abuse.generic.errors.category_required') },
});

export const CategoryField: React.FC<Props> = ({ type }) => {
	const { getErrorMessage } = useFormI18n();

	const {
		data: { categories },
		actions: { getLabel },
	} = useCategoryField({ type });

	return (
		<Box p={4}>
			<Field<FormValues['category']>
				name="category"
				type="radio"
				subscription={{ error: true, touched: true }}
				validate={validator}
			>
				{({ input: groupInput, meta }) => (
					<RadioGroup
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						error={meta.touched && getErrorMessage(meta.error)}
						onChange={groupInput.onChange}
					>
						{categories.map((category) => (
							<Field<FormValues['category']> key={category} type="radio" value={category} name="category">
								{({ input }) => (
									<Radio
										label={getLabel(category)}
										value={input.value}
										name={input.name}
										checked={input.checked}
										data-testid={`reportAbuse-category-${category}-radio`}
										data-qa={`reportAbuse-category-${category}-radio`}
									/>
								)}
							</Field>
						))}
					</RadioGroup>
				)}
			</Field>
		</Box>
	);
};
