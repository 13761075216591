import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { FCM_PETITION_PAGE_CONTACT_DM } from 'src/app/shared/fcm/configs';

export async function getContactDmConfig(utilityContext: UtilityContext): Promise<{
	emailEnabled: boolean;
	emailSubjectLine: string;
	emailBody: readonly string[];
}> {
	const { contactDmConfig } = await utilityContext.fcm.get({ contactDmConfig: FCM_PETITION_PAGE_CONTACT_DM });

	return {
		emailEnabled: !!contactDmConfig?.emailEnabled,
		emailSubjectLine: contactDmConfig?.emailSubjectLine || '',
		emailBody: contactDmConfig?.emailBody || [''],
	};
}
