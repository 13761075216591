import { useMemo } from 'react';

import omit from 'lodash/fp/omit';

import type { SessionUserRole } from 'src/shared/session';

import { useMappedLoadedState } from 'src/app/shared/hooks/state';

import { useCurrentUser, useCurrentUserAsync } from './currentUser';
import type { AsyncState } from './shared/types';

type Roles = Readonly<Record<SessionUserRole, boolean>>;

const FALLBACK: Roles = {
	staff: false,
	staffAdmin: false,
	support: false,
	audit: false,
	auditAdmin: false,
	petitionAdmin: false,
	engineering: false,
	product: false,
	campaigns: false,
	data: false,
	policy: false,
	policyAdmin: false,
	policySenior: false,
	supportAdmin: false,
	supportSenior: false,
	userResearch: false,
	communications: false,
};

const omitIdAndScoped = omit(['id', 'countryTeam', 'countryTeamAdmin']);

export function useCurrentUserRoles(): Roles {
	const roles = useCurrentUser()?.roles;
	return useMemo(() => (roles ? (omitIdAndScoped(roles) as Roles) : FALLBACK), [roles]);
}
export function useCurrentUserRolesAsync(): AsyncState<Roles> {
	return useMappedLoadedState(useCurrentUserAsync(), ({ value }) => ({
		value: value?.roles ? (omitIdAndScoped(value.roles) as Roles) : FALLBACK,
	}));
}

export function useCurrentUserRole(name: SessionUserRole): boolean {
	return useCurrentUserRoles()[name];
}
export function useCurrentUserRoleAsync(name: SessionUserRole): AsyncState<boolean> {
	return useMappedLoadedState(useCurrentUserRolesAsync(), ({ value }) => ({ value: value[name] }));
}

export function useIsStarter(): boolean {
	return useCurrentUserRole('petitionAdmin');
}
export function useIsStarterAsync(): AsyncState<boolean> {
	return useCurrentUserRoleAsync('petitionAdmin');
}
