import { Translate } from '@change-corgi/core/react/i18n';
import { createModalHook } from '@change-corgi/design-system/modals';

import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { PetitionReportAbuseForm } from '../components/PetitionReportAbuseForm';
import type { ReportContext } from '../shared/types';

import { ReportAbuseFormContainer } from './ReportAbuseForm';

export const useReportAbuseModal = createModalHook<{ v2?: boolean; reportContext: ReportContext }>({
	name: 'ReportAbuseModal',
	title: () => <Translate value="fe.components.petition_report_abuse.form_title" />,
	body: function ReportAbuseModalBody({ options: { v2, reportContext }, closeModal }) {
		return (
			<>
				<Header />
				{reportContext.type === 'petition' && v2 ? (
					<PetitionReportAbuseForm onSuccess={() => closeModal()} {...reportContext} />
				) : (
					<ReportAbuseFormContainer onSuccess={() => closeModal()} {...reportContext} />
				)}
				<Footer type={reportContext.type} />
			</>
		);
	},
});
