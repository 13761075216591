import type { Locale } from '@change-corgi/config/locales';

import intlNumberFormatLocaleMapping from 'config/intl-numberformat.json';

import { loadMissingFeatures } from '../polyfills';
import type { IntlCheck } from '../types';

export async function loadPolyfill(locale: string, deps: readonly IntlCheck[]): Promise<void> {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const intlLocale: string = intlNumberFormatLocaleMapping.localeMapping[locale as Locale] || locale;
	await loadMissingFeatures(deps, locale);
	await import(/* webpackChunkName: "polyfill/intl-numberformat/polyfill" */ '@formatjs/intl-numberformat/polyfill');
	await import(
		/* webpackChunkName: "polyfill/intl-numberformat/[request]" */ `@formatjs/intl-numberformat/locale-data/${intlLocale}.js`
	);
}
