import type { ComponentProps, JSX } from 'react';

import type { TranslationKey } from '@change-corgi/core/i18n';
import { translationKey } from '@change-corgi/core/i18n';
import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { Link as RouterLink } from '@change-corgi/core/react/router';
import { Separator } from '@change-corgi/design-system/components/content';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconSearch } from '@change-corgi/design-system/icons';
import { Box, Container, Flex } from '@change-corgi/design-system/layout';
import { linkResetStyles } from '@change-corgi/design-system/reset';
import type { ContainerVariant, ResponsiveValue } from '@change-corgi/design-system/theme';

import { isAtLeastIdentified } from 'src/shared/session';

import { useSessionAsync } from 'src/app/shared/hooks/session';
import { isLoaded, isLoading } from 'src/app/shared/utils/async';

import {
	LOGIN_URL,
	MEMBERSHIP_URL,
	MY_PETITIONS_URL,
	PETITIONS_URL,
	SEARCH_URL,
	START_PETITION_URL,
} from '../../shared/navigation';
import { DropDown } from '../components/DropDown';
import { Logo } from '../components/Logo';
import { useFcm } from '../hooks/fcm';
import { useLogoVariation } from '../hooks/logoVariation';
import { useHeaderTracking } from '../hooks/tracking/useHeaderTracking';

export const HEADER_HEIGHT = 58;
export const HEADER_HEIGHT_MOBILE = 48;

const headerHeights: ResponsiveValue<number | string> = HEADER_HEIGHT - 1;

// eslint-disable-next-line max-lines-per-function
export function HeaderContainer({
	containerVariant = 'small',
	...props
}: ComponentProps<typeof Box> & { containerVariant?: ContainerVariant }): JSX.Element | null {
	const { translate } = useI18n();
	const sessionState = useSessionAsync();
	const fcmState = useFcm();
	const isMembershipEnabled = isLoaded(fcmState) && fcmState.values.membershipEnabled;
	const logoVariation = useLogoVariation();
	const isGuest = isLoading(sessionState) || !isAtLeastIdentified(sessionState.value);
	const { trackHeaderClick } = useHeaderTracking();

	const headerLinks: ReadonlyArray<{
		url: string;
		labelKey: TranslationKey;
		onClick: React.MouseEventHandler<HTMLAnchorElement>;
		dataQa: string;
	}> = [
		{
			url: START_PETITION_URL,
			labelKey: translationKey('fe.components.header.start_a_petition'),
			onClick: trackHeaderClick,
			dataQa: 'header-sap-link',
		},
		{
			url: MY_PETITIONS_URL,
			labelKey: translationKey('fe.components.header.my_petitions'),
			onClick: () => undefined,
			dataQa: 'header-my-petitions-link',
		},
		{
			url: PETITIONS_URL,
			labelKey: translationKey('fe.components.header.browse'),
			onClick: () => undefined,
			dataQa: 'header-browse-link',
		},
		// prettier-ignore
		...(!isMembershipEnabled ? [] : [{
			url: MEMBERSHIP_URL,
			labelKey: translationKey('fe.components.header.membership'),
			onClick: () => undefined,
			dataQa: 'header-membership-link',
		}]),
	];

	return (
		<Box as="header" data-testid="site-header-container" {...props}>
			<Container variant={containerVariant} sx={{ height: headerHeights }} px={8}>
				<Flex sx={{ height: '100%', justifyContent: 'space-between', gap: 24 }}>
					<Flex sx={{ height: '100%', gap: 40 }} data-pii-safe>
						<Box>
							<RouterLink
								to="/"
								sx={{
									display: 'inline-block',
									height: '100%',
								}}
								aria-label="Change.org"
								data-qa="header-home-link"
							>
								<Flex sx={{ alignItems: 'center', height: '100%' }}>
									<Logo logoVariation={logoVariation} />
								</Flex>
							</RouterLink>
						</Box>
						<Box sx={{ display: ['none', null, 'block'] }}>
							<Flex sx={{ height: '100%', gap: 24 }}>
								{headerLinks.map(({ labelKey, url, onClick, dataQa }) => (
									// eslint-disable-next-line @typescript-eslint/no-base-to-string
									<Box key={String(labelKey)}>
										<RouterLink
											to={url}
											sx={{
												...linkResetStyles,
												display: 'inline-block',
												height: '100%',
												// eslint-disable-next-line @typescript-eslint/naming-convention
												'&:hover': {
													color: 'primary-changeRed',
												},
											}}
											onClick={onClick}
											data-qa={dataQa}
										>
											<Flex sx={{ height: '100%', alignItems: 'center' }}>
												<Translate value={labelKey} />
											</Flex>
										</RouterLink>
									</Box>
								))}
							</Flex>
						</Box>
					</Flex>

					<Flex sx={{ height: '100%', alignItems: 'center', gap: 24 }}>
						{isLoaded(sessionState) && (
							<>
								<Box sx={{ display: ['none', null, 'block'], height: '100%' }}>
									<RouterLink
										to={SEARCH_URL}
										sx={{
											...linkResetStyles,
											display: 'flex',
											alignItems: 'center',
											height: '100%',
											// eslint-disable-next-line @typescript-eslint/naming-convention
											'&:hover': {
												color: 'primary-changeRed',
											},
										}}
										aria-label={translate('fe.components.header.search')}
										data-qa="header-search-button"
									>
										<Icon icon={iconSearch} size="xl" />
									</RouterLink>
								</Box>

								{isGuest && (
									<Box sx={{ display: ['none', null, 'block'], height: '100%' }}>
										<RouterLink
											to={LOGIN_URL}
											sx={{
												...linkResetStyles,
												display: 'inline-block',
												height: '100%',
												// eslint-disable-next-line @typescript-eslint/naming-convention
												'&:hover': {
													color: 'primary-changeRed',
												},
											}}
											data-qa="header-login-link"
										>
											<Flex sx={{ height: '100%', alignItems: 'center' }}>
												<Translate value="fe.components.header.log_in" />
											</Flex>
										</RouterLink>
									</Box>
								)}

								<Box sx={{ display: isGuest ? ['block', null, 'none'] : 'block' }}>
									<DropDown
										headerHeights={headerHeights}
										session={sessionState.value}
										isMembershipEnabled={isMembershipEnabled}
									/>
								</Box>
							</>
						)}
					</Flex>
				</Flex>
			</Container>

			<Separator role="presentation" my={0} />
		</Box>
	);
}
