import type { RestrictedAccessConfigRoleFn } from 'src/shared/routes';
import type { SessionUserRole } from 'src/shared/session';

import { getUserCountryCode } from './api/getUserCountryCode';

export function countryTeamOfUser(
	allowedRoles: SessionUserRole[],
	userIdOrSlugParam: string,
): RestrictedAccessConfigRoleFn {
	// function is named for the benefit of src/server/misc/routesStats.ts
	// eslint-disable-next-line @typescript-eslint/no-shadow
	return async function countryTeamOfUser({ session, utilities, params }) {
		const slugOrId = params[userIdOrSlugParam];
		if (!slugOrId) throw new Error(`Unexpected empty param for "${userIdOrSlugParam}"`);
		if (!session.user) return false;
		const userHasAllowedRole = !!allowedRoles.find((role) => session.user.roles[role]);
		if (userHasAllowedRole) {
			const countryCode = await getUserCountryCode(utilities, slugOrId);
			return !!session.user.roles.countryTeam.find(({ scope }) => scope === countryCode);
		}
		return false;
	};
}
