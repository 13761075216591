import { INTL_LOCALE_CHECKS } from './intlLocale';
import { INTL_NUMBER_FORMAT_CHECKS } from './intlNumberFormat';
import { INTL_PLURAL_RULES_CHECKS } from './intlPluralRules';
import { INTL_RELATIVE_TIME_FORMAT_CHECKS } from './intlRelativeTimeFormat';
import { loadMissingFeatures } from './polyfills';

const CHECKS = [
	...INTL_NUMBER_FORMAT_CHECKS,
	...INTL_RELATIVE_TIME_FORMAT_CHECKS,
	...INTL_PLURAL_RULES_CHECKS,
	...INTL_LOCALE_CHECKS,
];

export async function setupIntl({ locale }: { locale: string }): Promise<void> {
	await loadMissingFeatures(CHECKS, locale);
}
