import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	SignatureSharedCreateSignatureMutation,
	SignatureSharedCreateSignatureMutationVariables,
} from './create.graphql';
import { normalizeSignPetitionErrorResponse } from './normalizeSignPetitionErrorResponse';
import { normalizeSignPetitionResponse } from './normalizeSignPetitionResponse';
import type { SignPetitionInput, SignPetitionResult, SignPetitionShareBandit } from './types';

const signatureQuery = gql`
	mutation SignatureSharedCreateSignature(
		$shouldRewardShareBandit: Boolean!
		$rewardShareBanditInput: BanditInput!
		$signatureInput: SignPetitionInput!
	) {
		rewardShareBandit: rewardBandit(input: $rewardShareBanditInput) @include(if: $shouldRewardShareBandit)

		signPetition(input: $signatureInput) {
			__typename
			... on SignPetitionMutationSuccess {
				redirectUrl
			}
			... on SignPetitionMutationGuestSignByExistingUserFailure {
				user {
					id
					connectedToFacebook
					passwordSet
				}
			}
		}
	}
`;

type Options = {
	signature: Omit<SignPetitionInput, 'apiVersion'>;
	shareBandit?: SignPetitionShareBandit;
};

export async function createSignature(
	{ signature, shareBandit }: Options,
	{ gql: { fetch } }: UtilityContext,
): Promise<SignPetitionResult> {
	let data: SignatureSharedCreateSignatureMutation | null | undefined;
	try {
		const result = await fetch<SignatureSharedCreateSignatureMutation, SignatureSharedCreateSignatureMutationVariables>(
			{
				query: signatureQuery,
				variables: {
					shouldRewardShareBandit: !!shareBandit,
					rewardShareBanditInput: {
						banditId: shareBandit?.id || '',
						variantName: shareBandit?.variant || '',
					},
					signatureInput: { ...signature, apiVersion: 2 },
				},
				path: `/signPetition/${signature.petitionId}`,
				rejectOnError: true,
				important: true, // to ensure this rate limited mutation is not batched
			},
		);
		({ data } = result);
	} catch (err) {
		return normalizeSignPetitionErrorResponse(err);
	}

	if (!data?.signPetition) throw new Error('Unexpected sign petition response');
	return normalizeSignPetitionResponse(data.signPetition);
}
