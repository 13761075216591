import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		enabled: { type: 'boolean' },
		activatedPercentage: { type: 'number' },
		blocklist: {
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					id: { type: 'string' },
				},
			},
		},
		openVideoCreationFromPetitionDetails: { type: 'boolean', optional: true },
		sortByScore: {
			type: 'object',
			optional: true,
			shape: {
				activatedPercentage: { type: 'number', optional: true },
				petitions: {
					type: 'array',
					shape: {
						type: 'object',
						shape: {
							id: { type: 'string' },
						},
					},
				},
			},
		},
	},
});

export const FCM_SUPPORTER_VOICES_DETAILS_PAGE = {
	name: 'supporter_voices_details_page',
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		enabled: false,
		activatedPercentage: 0,
		blocklist: [],
		openVideoCreationFromPetitionDetails: false,
		sortByScore: { petitions: [], activatedPercentage: 0 },
	} as ReturnType<typeof normalizer>,
};
