import { Translate } from '@change-corgi/core/react/i18n';
import { Button } from '@change-corgi/design-system/components/actions';

export const SubmitButton: React.FC = () => {
	return (
		<Button type="submit" data-qa="report-abuse-submit-button">
			<Translate value="corgi.policy_abuse.generic.form_submit" />
		</Button>
	);
};
