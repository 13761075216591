import type { SessionUser } from 'src/shared/session';

import { useMappedLoadedState } from 'src/app/shared/hooks/state';

import { useSession, useSessionAsync } from './session';
import type { AsyncState } from './shared/types';

/**
 * @returns undefined if the user is a GUEST or only IDENTIFIED
 */
export function useCurrentAuthenticatedUser(): SessionUser | undefined {
	const value = useSession();
	return value.loginState !== 'AUTHENTICATED' ? undefined : value.user;
}
/**
 * @returns state with undefined value if the user is a GUEST or only IDENTIFIED
 */
export function useCurrentAuthenticatedUserAsync(): AsyncState<SessionUser | undefined> {
	return useMappedLoadedState(useSessionAsync(), ({ value }) => ({
		value: value.loginState !== 'AUTHENTICATED' ? undefined : value.user,
	}));
}
