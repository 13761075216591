import { FormSpy } from 'react-final-form';

import { InlineMessage } from '@change-corgi/design-system/components/alerts';

import { useFormI18n } from 'src/app/shared/form/hooks';
import type { I18nFieldValidatorResult } from 'src/app/shared/form/validators';

export const FormError: React.FC = () => {
	const { getErrorMessage } = useFormI18n();
	return (
		<FormSpy subscription={{ submitError: true, submitting: true }}>
			{({ submitError, submitting }) =>
				submitError && !submitting ? (
					<InlineMessage size="large" variant="error" my={16}>
						{getErrorMessage(submitError as I18nFieldValidatorResult)}
					</InlineMessage>
				) : null
			}
		</FormSpy>
	);
};
