import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	shape: {
		isEnabled: { type: 'boolean' },
		impactVideos: {
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					title: {
						type: 'string',
					},
					url: {
						type: 'string',
					},
					ariaLabel: {
						type: 'string',
					},
					thumbnail: {
						type: 'string',
					},
				},
			},
		},
	},
});
export const FCM_STARFISH_HOMEPAGE_V2_CONTENT = createFcmConfig('starfish_homepage_v2_content', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		isEnabled: false,
		impactVideos: [],
	},
});
