import type { Locale } from '@change-corgi/config/locales';

import intlRelativeTimeFormatLocaleMapping from 'config/intl-relativetimeformat.json';

import { loadMissingFeatures } from '../polyfills';
import type { IntlCheck } from '../types';

export async function loadPolyfill(locale: string, deps: readonly IntlCheck[]): Promise<void> {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const intlLocale: string = intlRelativeTimeFormatLocaleMapping.localeMapping[locale as Locale] || locale;
	await loadMissingFeatures(deps, locale);
	await import(
		/* webpackChunkName: "polyfill/intl-relativetimeformat/polyfill" */ '@formatjs/intl-relativetimeformat/polyfill'
	);
	await import(
		/* webpackChunkName: "polyfill/intl-relativetimeformat/[request]" */ `@formatjs/intl-relativetimeformat/locale-data/${intlLocale}.js`
	);
}
