import { useCallback } from 'react';

import type { SignPetitionResult } from 'src/app/shared/api/signature';

import { useCurrentUserSignData } from './signData/currentUserSignData';
import type { SignPetitionHookInput } from './useSignPetition';
import { useSignPetition } from './useSignPetition';

const STATIC_DATA: Pick<SignPetitionHookInput, 'resubmit' | 'pendingReason' | 'userSawSignInterrupt'> = {
	resubmit: false,
	pendingReason: null,
	userSawSignInterrupt: false,
};

const STATIC_SUGGESTED_PETITION_DATA: Pick<
	SignPetitionHookInput,
	| 'reasonForSigning'
	| 'algorithm'
	| 'sourceLocation'
	| 'public'
	| 'shareInfoWithOrganization'
	| 'marketingCommsConsent'
	| 'inviteRecruiterId'
	| 'inviteRecruiterUuid'
	| 'inviteRequestedAt'
	| 'recaptchaResponse'
> = {
	// none of these were set by rendr-fe
	reasonForSigning: null,
	algorithm: null,
	sourceLocation: null,
	public: false,
	shareInfoWithOrganization: false,
	marketingCommsConsent: null,
	inviteRecruiterId: null,
	inviteRecruiterUuid: null,
	inviteRequestedAt: null,
	recaptchaResponse: null,
};

export type OneClickSignPetitionHookInput = Omit<
	SignPetitionHookInput,
	| keyof typeof STATIC_DATA
	| keyof typeof STATIC_SUGGESTED_PETITION_DATA
	| keyof ReturnType<typeof useCurrentUserSignData>
>;

type HookOptions = {
	pageContext: string;
};

export function useOneClickSignPetition(
	petitionId: string,
	hookOptions: HookOptions,
): (options?: OneClickSignPetitionHookInput) => Promise<SignPetitionResult> {
	const signPetition = useSignPetition(petitionId, hookOptions);
	const currentUserData = useCurrentUserSignData();

	return useCallback(
		async (signature = {}) => {
			return signPetition({
				signature: {
					...currentUserData,
					...STATIC_DATA,
					...STATIC_SUGGESTED_PETITION_DATA,
					promotionToken: null,
					promotionPlacement: null,
					promotionAlgorithm: null,
					...signature,
				},
			});
		},
		[signPetition, currentUserData],
	);
}
