import type { FeatureConfigData, FeatureConfigInfo } from '@change-corgi/core/fcm';
import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { PrefetchContext } from 'src/shared/prefetch';

import { createFcmGetter } from './createFcmGetter';
import { createFcmHook } from './createFcmHook';
import { createFcmPrefetch } from './createFcmPrefetch';
import type { HookOptions, MultiFcmState } from './shared/types';

type Functions<H extends boolean, P extends boolean, G extends boolean> = {
	/**
	 * Requests a React hook.
	 */
	hook?: H;
	/**
	 * Requests a prefetch function.
	 *
	 * Usually called from within a `prefetchData` function.
	 *
	 * This will store FCM values in the app cache so that it can be restored on hydration.
	 */
	prefetch?: P;
	/**
	 * Requests a prefetch function.
	 *
	 * Usually called from within a `prefetchUserData` function.
	 *
	 * This will NOT store FCM values in the app cache.
	 */
	getter?: G;
};

export type FcmFunctionsReturnValue<
	T extends Record<string, FeatureConfigInfo>,
	H extends boolean,
	P extends boolean,
	G extends boolean,
	HP extends string = 'values',
> = (H extends true ? { useFcm: () => MultiFcmState<T, HP> } : EmptyIntersection) &
	(P extends true ? { prefetchFcm: (context: PrefetchContext) => Promise<FeatureConfigData<T>> } : EmptyIntersection) &
	(G extends true
		? {
				getFcm: (utilityContext: UtilityContext) => Promise<FeatureConfigData<T>>;
			}
		: EmptyIntersection);

export type FcmFunctionsOptions<
	H extends boolean,
	P extends boolean,
	G extends boolean,
	HP extends string = 'values',
> = HookOptions<HP> & Functions<H, P, G>;

export function createFcmFunctions<
	T extends Record<string, FeatureConfigInfo>,
	H extends boolean,
	P extends boolean,
	G extends boolean,
	HP extends string = 'values',
>(configs: T, additionalOptions: HookOptions<HP> & Functions<H, P, G>): FcmFunctionsReturnValue<T, H, P, G, HP> {
	/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access */
	const res: any = {};
	const { hook, prefetch, getter, ...rest } = additionalOptions;
	if (hook) {
		res.useFcm = createFcmHook(configs, rest);
	}
	if (prefetch) {
		res.prefetchFcm = createFcmPrefetch(configs);
	}
	if (getter) {
		res.getFcm = createFcmGetter(configs);
	}
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return res;
	/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access */
}

export { createFcmHook };

export { FcmCacheProvider } from './provider';
export {
	useFcmState,
	// this should not be used by the app
	// it is only exposed for the benefit of the tests-helpers module
	FcmStateContext as __internal_FcmStateContext,
} from './context';
export type { FcmCacheState } from './context';
export { useFcmCachePrefetchContext, createFcmCachePrefetchContext } from './cache';
export type { MultiFcmState } from './shared/types';
