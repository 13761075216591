import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { FCM_POLICY_ABUSE_PETITION_REPORT_MODAL } from 'src/app/shared/fcm/configs';

export async function isPolicyAbuseExperimentEnabled(utilityContext: UtilityContext) {
	const fcm = await utilityContext.fcm.get({
		policyAbusePetitionReportModal: FCM_POLICY_ABUSE_PETITION_REPORT_MODAL,
	});
	const experiment = utilityContext.experiments.get(FCM_POLICY_ABUSE_PETITION_REPORT_MODAL.name);
	// Verifying that the FCM is enabled and the experiment is not control
	const isExperimentEnabled = fcm.policyAbusePetitionReportModal && experiment.variation !== 'control';
	// Treating the experiment ONLY if the FCM is enabled
	void (fcm.policyAbusePetitionReportModal && experiment.treat());
	return isExperimentEnabled;
}
