import { useCallback } from 'react';

import type { SessionUser } from 'src/shared/session';

import { useCurrentUserAsync } from 'src/app/shared/hooks/session';
import { isLoaded } from 'src/app/shared/utils/async';

import { useLoginModal } from './useLoginModal';

/**
 * @deprecated not production-ready, please don't use in production
 */
export function useLoginWithModal(): () => Promise<SessionUser | undefined> {
	const currentUserState = useCurrentUserAsync();
	const email = isLoaded(currentUserState) ? currentUserState.value?.email : undefined;

	const openLoginModal = useLoginModal();

	return useCallback(async () => {
		const modalResult = await openLoginModal({ email }).waitForResult();
		return modalResult?.user;
	}, [openLoginModal, email]);
}
