import type { ComponentProps } from 'react';

import { Flex } from '@change-corgi/design-system/layout';

export const ActionBar: React.FC<ActionBarProps> = ({ children, ...rest }) => {
	return (
		<Flex
			sx={{
				backgroundColor: ['primary-white', 'transparent'],
				borderTopWidth: [1, 0],
				borderTopColor: 'neutral-grey100',
				borderTopStyle: 'solid',
				p: [16, 0],
				position: ['fixed', 'static'],
				left: [0, 'auto'],
				right: [0, 'auto'],
				bottom: [0, 'auto'],
				zIndex: 1,
			}}
			{...rest}
		>
			{children}
		</Flex>
	);
};

type ActionBarProps = ComponentProps<typeof Flex>;
