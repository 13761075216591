import { Field } from 'react-final-form';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Select } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import { useFormI18n } from 'src/app/shared/form/hooks';

import type { FormValues, ReportType } from '../../shared/types';

import { useSubSubCategoryField } from './hooks/useSubSubCategoryField';

type Props = Readonly<{
	type: ReportType;
}>;

export const SubSubCategoryField: React.FC<Props> = ({ type }) => {
	const { translate } = useI18n();
	const { getErrorMessage } = useFormI18n();

	const {
		data: { subSubCategories },
		actions: { getLabel },
	} = useSubSubCategoryField({ type });

	if (subSubCategories.length === 0) return null;

	return (
		<Box mb={8}>
			<Field<FormValues['subSubCategory']> name="subSubCategory">
				{({ input, meta }) => (
					<Select
						name={input.name}
						value={input.value}
						onChange={input.onChange}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						error={meta.touched && getErrorMessage(meta.error)}
						data-testid="reportAbuse-subSubCategory-select"
						data-qa="reportAbuse-subSubCategory-select"
						label={translate('corgi.policy_abuse.generic.sub_sub_category.label')}
					>
						<option value="">{translate('corgi.policy_abuse.generic.sub_sub_category.prompt')}</option>
						{subSubCategories.map((subSubCategory) => (
							<option value={subSubCategory} key={subSubCategory}>
								{getLabel(subSubCategory)}
							</option>
						))}
					</Select>
				)}
			</Field>
		</Box>
	);
};
