import type { History } from '@remix-run/router';

import { googleAnalyticsTrackingIds } from '@change-corgi/config/googleAnalytics';
import type { EnvironmentUtils } from '@change-corgi/core/environment';
import type { ErrorReporter } from '@change-corgi/core/errorReporter/common';
import type { GoogleAnalytics } from '@change-corgi/core/googleAnalytics';
import { createGoogleAnalytics as _createGoogleAnalytics } from '@change-corgi/core/googleAnalytics';
import { getLocation } from '@change-corgi/core/window';

import type { Session } from 'src/shared/session';

type Options = Readonly<{
	errorReporter: ErrorReporter;
	environment: EnvironmentUtils;
	session: Session;
	history: History;
}>;

function getGoogleAnalyticsTrackingId(environment: EnvironmentUtils): string {
	return googleAnalyticsTrackingIds[environment.getEnvironment()] || googleAnalyticsTrackingIds.development;
}

function setupGoogleAnalytics({ googleAnalytics, history }: { googleAnalytics: GoogleAnalytics; history: History }) {
	const location = getLocation();
	googleAnalytics.ga('set', 'page', location.pathname + location.search + location.hash);
	googleAnalytics.ga('send', 'pageview');
	history.listen(({ location: historyLocation }) => {
		googleAnalytics.ga('set', 'page', historyLocation.pathname + historyLocation.search + historyLocation.hash);
		googleAnalytics.ga('send', 'pageview');
	});
}

export function createGoogleAnalytics({ errorReporter, session, environment, history }: Options): GoogleAnalytics {
	const googleAnalytics = _createGoogleAnalytics({
		trackingId: getGoogleAnalyticsTrackingId(environment),
		reportError: errorReporter.createSampledReporter(0.01),
	}).toggle(session.cookiePrefs.analytics);

	setupGoogleAnalytics({ googleAnalytics, history });

	return googleAnalytics;
}
