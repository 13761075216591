import { Flex } from '@change-corgi/design-system/layout';

import type { ReportType } from '../../shared/types';

import { BaseQuestionField } from './components/BaseQuestionField';
import { useQuestionField } from './hooks/useQuestionField';

type Props = {
	type: ReportType;
};

export const QuestionField: React.FC<Props> = ({ type }) => {
	const {
		data: { parent, questions },
	} = useQuestionField({ type });

	if (!parent || !questions.length) return null;

	return (
		<Flex mb={8} sx={{ flexDirection: 'column', gap: 8 }}>
			{questions.map((question, idx) => (
				<BaseQuestionField key={question.name} type={type} parent={parent} question={question} idx={idx + 1} />
			))}
		</Flex>
	);
};
