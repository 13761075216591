import type { JSX } from 'react';

import { parseHtml } from '@change-corgi/core/react/html';
import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { FakeLink } from '@change-corgi/design-system/components/actions';
import { Separator } from '@change-corgi/design-system/components/content';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconLock } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import type { SessionUser } from 'src/shared/session';

import { LoginModalForm } from './components/LoginModalForm';
import { LoginModalSocialAuth } from './components/LoginModalSocialAuth';

type Props = {
	googleAuthEnabled: boolean;
	user?: SessionUser;
	email: string;
	showContinueAsGuest: boolean;
	onSuccess: (user: SessionUser) => void;
	onContinueAsGuest: () => void;
};

export function LoginModalBody({
	user,
	email,
	googleAuthEnabled,
	onSuccess,
	showContinueAsGuest,
	onContinueAsGuest,
}: Props): JSX.Element {
	const { translate } = useI18n();

	return (
		<>
			{showContinueAsGuest && (
				<Text as="div" sx={{ textAlign: 'center' }} size="small" mt={16} mb={24}>
					<Flex
						// FIXME: Change this color to those in the DS
						backgroundColor="#ffe9aa"
						sx={{
							borderRadius: '100%',
							transform: 'translateY(2px)',
							height: 28,
							width: 28,
							display: 'inline-flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						mr={8}
					>
						{/* FIXME: Change this color to those in the DS */}
						<Icon icon={iconLock} size="m" color="#b48629" />
					</Flex>
					<Translate value="fe.components.login_or_join.saved_payment_info" />
				</Text>
			)}
			<LoginModalSocialAuth onSuccess={onSuccess} googleAuthEnabled={googleAuthEnabled} />
			<Separator label={translate('corgi.login_or_signup.email.or')} my={36} />
			<LoginModalForm user={user} email={email} onSuccess={onSuccess} />
			{showContinueAsGuest && (
				<Text as="div" sx={{ textAlign: 'center' }} my={16}>
					<FakeLink onClick={onContinueAsGuest} data-qa="guest-login-button">
						<Translate value="fe.pages.sponsors.guest_login" />
					</FakeLink>
				</Text>
			)}
			<Text as="p" size="small" mt={32}>
				{parseHtml(
					translate('fe.components.login_or_join.privacy_notice', {
						tosUrl: '/policies/terms-of-service',
						privacyUrl: '/policies/privacy',
					}),
					{
						allowedAttrs: ['target'],
					},
				)}
			</Text>
		</>
	);
}
