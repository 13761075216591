import { useCallback } from 'react';

import { translationKey } from '@change-corgi/core/i18n';
import { useI18n } from '@change-corgi/core/react/i18n';

import type { FieldName, FieldType, ReportType } from '../../shared/types';

type Result = ModelHookResult<
	undefined,
	{
		getLabel: (name: FieldType) => string;
	}
>;

// This is intended to be used on all field types excluding questions, questions will have their own logic
export function useLabel(reportType: ReportType, fieldType: Exclude<FieldName, 'question'>): Result {
	const { translate, translationExists } = useI18n();

	return {
		actions: {
			getLabel: useCallback(
				(name: FieldType) => {
					const suffix = `${fieldType}.${name}`;

					return translate(
						// Adding reportType so in the future we can handle different
						// labels for the same question depending on the ReportType
						translationExists(`corgi.policy_abuse.${reportType}.${suffix}`)
							? translationKey(`corgi.policy_abuse.${reportType}.${suffix}`)
							: translationKey(`corgi.policy_abuse.generic.${suffix}`),
					);
				},
				[fieldType, reportType, translate, translationExists],
			),
		},
	};
}
