import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Button } from '@change-corgi/design-system/components/actions';

export function Buttons(): JSX.Element {
	return (
		<Button type="submit" data-qa="report-abuse-submit-button">
			<Translate value="fe.components.petition_report_abuse.form_submit" />
		</Button>
	);
}
