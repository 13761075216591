import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

type PsfShareChannel =
	| 'copy'
	| 'facebook'
	| 'instagram'
	| 'whatsapp'
	| 'twitter'
	| 'email'
	| 'facebookMessenger'
	| 'sms'
	| 'line'
	| 'nextdoor'
	| 'vk'
	| 'mobileNativeShare';

const SHARE_CHANNELS: readonly PsfShareChannel[] = [
	'facebook',
	'instagram',
	'whatsapp',
	'twitter',
	'email',
	'facebookMessenger',
	'sms',
	'line',
	'nextdoor',
	'vk',
	'mobileNativeShare',
];

export const FCM_PSF_SHARE_BUTTON_CONFIG = createFcmConfig('psf_share_button_config', {
	normalizer: createJsonNormalizer(
		createShapeNormalizer({
			type: 'array',
			shape: {
				type: 'enum',
				values: SHARE_CHANNELS,
			},
		}),
	),
	defaultValue: ['copy', 'facebook', 'instagram', 'whatsapp', 'twitter', 'email', 'facebookMessenger', 'sms'],
});
