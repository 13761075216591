import { CATEGORIES_MAPPING, SUB_CATEGORIES_MAPPING, SUB_SUB_CATEGORIES_MAPPING } from '../consts';
import type { Category, Question, QuestionSelect, ReportType, SubCategory, SubSubCategory } from '../types';

// Returns the corresponding sub categories or questions for any given category
export function getSubCategoriesOrQuestions(type: ReportType, category: Category): QuestionSelect {
	return CATEGORIES_MAPPING[type][category];
}

// Returns the corresponding sub categories or questions for any given subcategory
export function getSubSubCategoriesOrQuestions(type: ReportType, subCategory: SubCategory): QuestionSelect {
	return SUB_CATEGORIES_MAPPING[type][subCategory];
}

// Returns the corresponding questions for any given subsubcategory
export function getQuestions(type: ReportType, subSubCategory: SubSubCategory): readonly Question[] {
	return SUB_SUB_CATEGORIES_MAPPING[type][subSubCategory];
}
