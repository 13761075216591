import { useCallback } from 'react';

import type { Session } from 'src/shared/session';

import { useCountryCode, useLocale } from 'src/app/shared/hooks/l10n';

export type L10nChanged = 'both' | 'locale' | 'country_code' | 'none';

export function useGetL10nChanged(): (newSession: Session) => L10nChanged {
	const locale = useLocale();
	const countryCode = useCountryCode();

	return useCallback(
		(newSession: Session) => {
			const localeChanged = newSession.locale.localeCode !== locale;
			const countryCodeChanged = newSession.country.countryCode !== countryCode;
			if (localeChanged && countryCodeChanged) return 'both';
			if (localeChanged) return 'locale';
			if (countryCodeChanged) return 'country_code';
			return 'none';
		},
		[locale, countryCode],
	);
}
