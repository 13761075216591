import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		minSignatures: { type: 'number' },
		maxSignatures: { type: 'number' },
		minPercentage: { type: 'number' },
		percentageForPetitions: {
			type: 'array',
			shape: {
				type: 'object',
				shape: {
					petitionId: { type: 'string' },
					percentage: { type: 'number' },
				},
			},
		},
	},
});

export const FCM_REGISTERED_VOTERS_CONFIG = createFcmConfig('registered_voters_config', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		minSignatures: 100,
		maxSignatures: 1000,
		minPercentage: 0.6,
		percentageForPetitions: [
			{
				petitionId: '123',
				percentage: 0.75,
			},
		],
	} as ReturnType<typeof normalizer>,
});
