import type { RestrictedAccessConfigRoleFn } from 'src/shared/routes';
import type { SessionUserRole } from 'src/shared/session';

export function hasRoleAndCountryTeam(
	allowedRole: SessionUserRole,
	userIdOrSlugParam: string,
): RestrictedAccessConfigRoleFn {
	// function is named for the benefit of src/server/misc/routesStats.ts
	// eslint-disable-next-line @typescript-eslint/no-shadow
	return function hasRoleAndCountryTeam({ session, params }) {
		const slugOrId = params[userIdOrSlugParam];
		if (!slugOrId) throw new Error(`Unexpected empty param for "${userIdOrSlugParam}"`);
		if (!session.user) return false;
		const userHasAllowedRole = !!session.user.roles[allowedRole];
		if (userHasAllowedRole) {
			// For some reason History Page only requires the user to have countryTeam permission, not specific to user's country
			return !!session.user.roles.countryTeam.find(({ scope }) => scope);
		}
		return false;
	};
}
