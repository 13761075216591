import { createJsonNormalizer, createShapeNormalizer } from '@change-corgi/config/fcm/normalizers';

import { createFcmConfig } from '../shared/factory';

const normalizer = createShapeNormalizer({
	type: 'object',
	optional: true,
	shape: {
		enabled: { type: 'boolean' },
		newDmCtaEnabled: { type: 'boolean' },
		newDmCtaCopy: { type: 'string' },
		newDmCtaLink: { type: 'string' },
		newDmCtaSources: { type: 'array', shape: { type: 'string' } },
	},
});

export const FCM_PETITION_PAGE_DM_CARD_CONFIG = createFcmConfig('petition_page_dm_card_config', {
	normalizer: createJsonNormalizer(normalizer),
	defaultValue: {
		enabled: false,
		newDmCtaEnabled: false,
		newDmCtaCopy: 'Respond now',
		newDmCtaLink: '',
		newDmCtaSources: [],
	},
});
