import type { PropsWithChildren } from 'react';
import React from 'react';

import { Text } from '@change-corgi/design-system/typography';

export const CalloutBody: React.FC<CalloutBodyProps> = ({ children }) => {
	return <Text>{children}</Text>;
};

type CalloutBodyProps = PropsWithChildren;
