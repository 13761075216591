import { useMemo } from 'react';

import { translationKey } from '@change-corgi/core/i18n';
import { useI18n } from '@change-corgi/core/react/i18n';

import type { I18nFieldValidator } from 'src/app/shared/form/validators';
import { composeValidators, maxLengthValidator, requiredValidator } from 'src/app/shared/form/validators';

import { useQuestionLabel } from '../../../../../../../../hooks/useQuestionLabel';
import type { FieldType, ReportType, TextQuestion } from '../../../../../../../../shared/types';

type Result = ModelHookResult<{
	fieldName: string;
	questionLabel: string;
	validator: I18nFieldValidator<string | undefined>;
}>;

type Props = {
	type: ReportType;
	parent: FieldType;
	question: TextQuestion;
	idx: number;
};

const MAXIMUM_CHARACTER_LENGTH = 5000;

export function useTextQuestionField({ type, parent, question, idx }: Props): Result {
	const { localizeNumber } = useI18n();
	const {
		data: { questionLabel },
	} = useQuestionLabel(type, parent, question.name);

	const validator = useMemo(() => {
		const maxCharactersValidator = maxLengthValidator({
			i18n: {
				key: translationKey('corgi.policy_abuse.generic.errors.question.too_long'),
				replacements: { maximumCharacters: localizeNumber(MAXIMUM_CHARACTER_LENGTH) },
			},
			length: MAXIMUM_CHARACTER_LENGTH,
		});
		if (question.required)
			return composeValidators(
				requiredValidator({
					i18n: { key: translationKey('corgi.policy_abuse.generic.errors.question.required') },
				}),
				maxCharactersValidator,
			);
		return maxCharactersValidator;
	}, [localizeNumber, question.required]);

	// This is used by the form to know which property we're accessing
	const fieldName = `question${idx}`;
	return {
		data: {
			fieldName,
			questionLabel,
			validator,
		},
	};
}
