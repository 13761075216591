import { useCallback, useEffect, useMemo } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import type { Session } from 'src/shared/session';

import { isLoaded } from 'src/app/shared/utils/async';

import { useCookiePrefs, useCookiePrefsAsync } from '../../get/cookiePrefs';
import { useLoginStateAsync } from '../../get/loginState';
import type { AsyncState } from '../../get/shared/types';
import type { CookiePrefsRequest } from '../../types';
import { useSessionMutations } from '../mutations';

import { persistCookiePrefs, saveCookiePrefs } from './api';

export function useMutableCookiePrefs(): Readonly<
	[Session['cookiePrefs'], (prefs: CookiePrefsRequest) => Promise<'success' | 'local-only'>]
> {
	const { setCookiePrefs: setCookiePrefsInState } = useSessionMutations();
	const cookiePrefs = useCookiePrefs();
	const utilityContext = useUtilityContext();

	const setCookiePrefs = useCallback(
		async (prefs: CookiePrefsRequest) => {
			const result = await saveCookiePrefs(prefs, utilityContext);
			setCookiePrefsInState(prefs);
			return result;
		},
		[setCookiePrefsInState, utilityContext],
	);

	return useMemo(() => [cookiePrefs, setCookiePrefs], [cookiePrefs, setCookiePrefs]);
}

export function useMutableCookiePrefsAsync(): AsyncState<
	Readonly<[Session['cookiePrefs'], (prefs: CookiePrefsRequest) => Promise<void>]>
> {
	const { setCookiePrefs: setCookiePrefsInState } = useSessionMutations();
	const cookiePrefs = useCookiePrefsAsync();
	const utilityContext = useUtilityContext();

	const setCookiePrefs = useCallback(
		async (prefs: CookiePrefsRequest) => {
			await saveCookiePrefs(prefs, utilityContext);
			setCookiePrefsInState(prefs);
		},
		[setCookiePrefsInState, utilityContext],
	);

	return useMemo(() => {
		if (cookiePrefs.status === 'loaded') {
			return { status: 'loaded' as const, value: [cookiePrefs.value, setCookiePrefs] };
		}
		return cookiePrefs;
	}, [cookiePrefs, setCookiePrefs]);
}

export function usePersistCookiePrefs(): void {
	const loginState = useLoginStateAsync();
	const utilityContext = useUtilityContext();

	useEffect(() => {
		if (!isLoaded(loginState)) return;
		(async () => {
			try {
				await persistCookiePrefs(loginState.value, utilityContext);
			} catch {
				// ignore errors
			}
		})();
	}, [loginState, utilityContext]);
}
