import { useMemo } from 'react';

import { useFormState } from 'react-final-form';

import { useLabel } from '../../../../hooks/useLabel';
import type { FormValues, ReportType, SubSubCategory } from '../../../../shared/types';
import { getSubSubCategoriesOrQuestions } from '../../../../shared/utils';

type Result = ModelHookResult<
	{
		subSubCategories: readonly SubSubCategory[];
	},
	{
		getLabel: ReturnType<typeof useLabel>['actions']['getLabel'];
	}
>;

export function useSubSubCategoryField({ type }: { type: ReportType }): Result {
	const {
		values: { subCategory },
	} = useFormState<FormValues>({
		subscription: { values: true },
	});

	const subSubCategories = useMemo(() => {
		const subSubCategoriesOrQuestions = subCategory ? getSubSubCategoriesOrQuestions(type, subCategory) : undefined;
		if (subSubCategoriesOrQuestions?.type === 'sub_sub_category') return subSubCategoriesOrQuestions?.value;
		return [];
	}, [type, subCategory]);

	const {
		actions: { getLabel },
	} = useLabel(type, 'sub_sub_category');

	return {
		data: {
			subSubCategories,
		},
		actions: {
			getLabel,
		},
	};
}
