import { Field } from 'react-final-form';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Select } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import { useFormI18n } from 'src/app/shared/form/hooks';

import type { FieldType, OptionsQuestion, ReportType } from '../../../../../../shared/types';

import { useOptionsQuestionField } from './hooks/useOptionsQuestionField';

type Props = {
	type: ReportType;
	parent: FieldType;
	question: OptionsQuestion;
	idx: number;
};

export const OptionsQuestionField: React.FC<Props> = ({ type, parent, question, idx }) => {
	const { translate } = useI18n();
	const { getErrorMessage } = useFormI18n();
	const {
		data: { fieldName, questionLabel, validator },
		actions: { getQuestionOptionsLabel },
	} = useOptionsQuestionField({ type, parent, question, idx });

	return (
		<Box>
			<Field<string | undefined> name={fieldName} validate={validator}>
				{({ input, meta }) => (
					<Select
						name={input.name}
						value={input.value}
						onChange={input.onChange}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						error={meta.touched && getErrorMessage(meta.error)}
						data-testid="reportAbuse-option"
						data-qa="reportAbuse-option"
						label={questionLabel}
					>
						<option value="">{translate('corgi.policy_abuse.generic.question.prompt')}</option>
						{question.options.map((options) => {
							const optionLabel = getQuestionOptionsLabel(options);
							return (
								<option value={optionLabel} key={options}>
									{optionLabel}
								</option>
							);
						})}
					</Select>
				)}
			</Field>
		</Box>
	);
};
