import type { FieldType, Question, ReportType } from '../../../../shared/types';

import { OptionsQuestionField } from './components/OptionsQuestionField';
import { TextQuestionField } from './components/TextQuestionField';

type Props = {
	type: ReportType;
	parent: FieldType;
	question: Question;
	idx: number;
};

export const BaseQuestionField: React.FC<Props> = ({ type, parent, question, idx }) => {
	switch (question.type) {
		case 'options':
			return <OptionsQuestionField type={type} parent={parent} question={question} idx={idx} />;
		case 'text':
		default:
			return <TextQuestionField type={type} parent={parent} question={question} idx={idx} />;
	}
};
