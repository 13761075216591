import type { Session } from 'src/shared/session';

import { useMappedLoadedState } from 'src/app/shared/hooks/state';

import { useSession, useSessionAsync } from './session';
import type { AsyncState } from './shared/types';

type CookiePrefs = Omit<Session['cookiePrefs'], '__typename'>;

export function useCookiePrefs(): CookiePrefs {
	return useSession().cookiePrefs;
}
export function useCookiePrefsAsync(): AsyncState<CookiePrefs> {
	return useMappedLoadedState(useSessionAsync(), ({ value }) => ({ value: value.cookiePrefs }));
}

export function useCookiePref(name: keyof CookiePrefs): boolean {
	return useCookiePrefs()[name];
}
export function useCookiePrefAsync(name: keyof CookiePrefs): AsyncState<boolean> {
	return useMappedLoadedState(useCookiePrefsAsync(), ({ value }) => ({ value: value[name] }));
}
