import { shouldPolyfill } from '@formatjs/intl-numberformat/should-polyfill';

import { INTL_LOCALE_CHECKS } from '../intlLocale';
import { INTL_PLURAL_RULES_CHECKS } from '../intlPluralRules';

import { loadPolyfill } from './polyfill';

const polyfill = (() => {
	const promises: Partial<Record<string, Promise<void>>> = {};

	return async function innerPolyfill(locale: string) {
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		if (promises[locale]) {
			return promises[locale];
		}

		promises[locale] = loadPolyfill(locale, NUMBER_FORMAT_CHECKS.dependencies);
		return promises[locale];
	};
})();

export const NUMBER_FORMAT_CHECKS = {
	dependencies: [...INTL_PLURAL_RULES_CHECKS, ...INTL_LOCALE_CHECKS],
	checkNativeIntlFeature: (locale: string): boolean => !!window.Intl && !shouldPolyfill(locale),
	polyfill,
};

export const INTL_NUMBER_FORMAT_CHECKS = [NUMBER_FORMAT_CHECKS];
