import { useCallback, useMemo } from 'react';

import { useKnownAvailableParams } from '@change-corgi/core/react/router';

import { useStarterOnboardingPageData } from 'src/app/pages/starterOnboarding/pageContext';
import { useLocale } from 'src/app/shared/hooks/l10n';
import { useCurrentUserId } from 'src/app/shared/hooks/session';
import type { ShareActionsOptions, ShareChannel } from 'src/app/shared/hooks/share';

export function useGetStarterOnboardingShareTrackingData(): ShareActionsOptions<ShareChannel>['getTrackingData'] {
	const userId = useCurrentUserId();
	const { step } = useKnownAvailableParams<{ step: string }>();
	const locale = useLocale();
	const {
		data: { petition },
	} = useStarterOnboardingPageData();

	// TODO: if needed, set context and view_context based on step
	const commonTrackingData = useMemo(
		() => ({
			context: 'starter_onboarding_share_flow',
			view_context: 'starter_onboarding_share_flow',
			source_location: step,
			petition_id: `petition_${petition.id}`,
			own_petition: userId === petition.user.id,
		}),
		[step, petition.id, petition.user.id, userId],
	);

	return useCallback(
		(_channel: ShareChannel) => {
			switch (_channel) {
				case 'facebook':
				case 'facebookMessenger':
					return {
						...commonTrackingData,
						api: true,
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						petition_status: `${petition.status}`.toLowerCase(),
						fht: `fht-${petition.id}-${locale.toLowerCase()}`,
					};
				default:
					return commonTrackingData;
			}
		},
		[commonTrackingData, petition.status, petition.id, locale],
	);
}
