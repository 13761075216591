import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { Session } from 'src/shared/session';

import type { CookiePrefsRequest } from '../../types';

import { setCookiePrefs } from './setCookiePrefs';

const LOCAL_STORAGE_KEY = '_change_cookie_prefs';

export async function saveCookiePrefs(
	prefs: CookiePrefsRequest,
	utilityContext: UtilityContext,
): Promise<'success' | 'local-only'> {
	const { persisted } = await setCookiePrefs(prefs, utilityContext);

	if (!persisted) {
		localStorage && localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(prefs));
		return 'local-only';
	}
	localStorage && localStorage.removeItem(LOCAL_STORAGE_KEY);
	return 'success';
}

function getParsedPrefsFromStorage(): CookiePrefsRequest | undefined {
	const prefsJson = (localStorage && localStorage.getItem(LOCAL_STORAGE_KEY)) || null;

	if (!prefsJson) return undefined;

	try {
		return JSON.parse(prefsJson) as CookiePrefsRequest;
	} catch (e) {
		return undefined;
	}
}

/*
 * Persists cookie prefs if present in localStorage. Mostly useful when a guest changes their prefs, then logs in.
 */
export async function persistCookiePrefs(
	loginState: Session['loginState'],
	utilityContext: UtilityContext,
): Promise<void> {
	if (loginState !== 'AUTHENTICATED') return;

	const prefs = getParsedPrefsFromStorage();

	if (!prefs) return;

	const { persisted } = await setCookiePrefs(prefs, utilityContext);
	if (persisted) {
		localStorage && localStorage.removeItem(LOCAL_STORAGE_KEY);
	}
}

export type { CookiePrefsRequest };
