import type { JSX } from 'react';

import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { StaticPageStateProvider } from 'src/shared/pageState';

import { ErrorApp } from './ErrorApp';
import type { AppOptions } from './types';

export function createClientErrorApp(options: AppOptions): () => JSX.Element | null {
	const { hasMainTranslations } = options.utilities.i18n;

	const pageStateContext = { state: {} };

	if (options.ssr) {
		throw new Error('Invalid AppOptions');
	}

	options.utilities.googleAnalytics.ga('send', 'event', 'errors', '500 page');

	const router = createBrowserRouter([
		{
			path: '*',
			element: (
				<HelmetProvider>
					<ErrorApp hasTranslations={hasMainTranslations} {...options} />
				</HelmetProvider>
			),
		},
	]);

	// eslint-disable-next-line react/function-component-definition
	return function RoutedError(): JSX.Element | null {
		return (
			<StaticPageStateProvider context={pageStateContext}>
				<RouterProvider router={router} />
			</StaticPageStateProvider>
		);
	};
}
