import type { PropsWithChildren } from 'react';
import React from 'react';

import { Heading } from '@change-corgi/design-system/typography';

export const CalloutHeading: React.FC<CalloutHeadingProps> = ({ as, children }) => {
	return (
		<Heading as={as} size="h4" sx={{ marginBlock: 4 }}>
			{children}
		</Heading>
	);
};

type CalloutHeadingProps = PropsWithChildren<{
	as: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}>;
