import type { PropsWithChildren } from 'react';

import { Icon } from '@change-corgi/design-system/components/icon';
import type { IconComponent } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';

export const Callout: React.FC<CalloutProps> = ({ icon, children }) => {
	return (
		<Box
			variant="bordered"
			sx={{ borderColor: 'neutral-grey100', bg: 'primary-greyBackground', display: 'flex', borderRadius: 'big' }}
			p={24}
		>
			<Icon
				icon={icon}
				size={16}
				color="primary-white"
				backgroundColor="primary-changeRed"
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginRight: 16,
					minWidth: 32,
					minHeight: 32,
					borderRadius: 'full',
				}}
			/>
			<Flex sx={{ flexDirection: 'column', gap: 8 }}>{children}</Flex>
		</Box>
	);
};

type CalloutProps = PropsWithChildren<{
	icon: IconComponent;
	bodyVariant?: 'inline' | 'block';
}>;
