import { useCallback } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import type { FormValues, ReportContext } from '../../shared/types';

import { reportAbuse as reportAbuseApi } from './api';
import { normalizeFormValues, normalizePayload } from './normalize';

type Result = ModelHookResult<
	undefined,
	{
		reportAbuse: (formValues: FormValues) => Promise<boolean>;
	}
>;

export function useReportAbuse({ reportContext }: { reportContext: ReportContext; onSuccess?: () => void }): Result {
	const utilityContext = useUtilityContext();

	const reportAbuse = useCallback(
		async (formValues: FormValues) => {
			const result = await reportAbuseApi(
				{
					...normalizeFormValues(reportContext.type, formValues),
					...normalizePayload(reportContext),
				},
				utilityContext,
			);
			return result;
		},
		[reportContext, utilityContext],
	);

	return {
		actions: {
			reportAbuse,
		},
	};
}
