import type { TranslationKey } from '@change-corgi/core/i18n';

import type { OnlineShareChannel } from 'src/app/pages/starterOnboarding/shared/types';
import type { ShareAction } from 'src/app/shared/hooks/share';
import { useAvailableShareActions } from 'src/app/shared/hooks/share';

import { SHARE_BUTTON_CONFIG } from './constants';
import { useStarterOnboardingShareChannels } from './useStarterOnboardingShareChannels';
import { useStarterOnboardingShareOptions } from './useStarterOnboardingShareOptions';

export type StarterOnboardingShareActionsReturn = Array<
	ShareAction<OnlineShareChannel> & {
		label: TranslationKey;
		successLabel?: TranslationKey;
	}
>;

export function useStarterOnboardingShareActions<T extends OnlineShareChannel[]>(
	channels: T,
): StarterOnboardingShareActionsReturn {
	const options = useStarterOnboardingShareOptions();
	const allChannels = useStarterOnboardingShareChannels();
	const availableChannels = allChannels.filter((channel) => channels.includes(channel));
	return useAvailableShareActions(availableChannels, options).map((action) => {
		const { label, successLabel } = SHARE_BUTTON_CONFIG[action.channel];
		return {
			...action,
			channel: action.channel,
			label,
			successLabel,
		};
	});
}
