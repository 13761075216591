import { useMemo } from 'react';
import type { JSX } from 'react';

import type { Session } from 'src/shared/session';

import { getAppStatePageStateData } from './getAppStatePageStateData';
import { getViewerPageStateData } from './getViewerPageStateData';
import { SetPageStateValue } from './SetPageStateValue';

type Props = Readonly<{
	session?: Session;
	locale: string;
	countryCode: string;
}>;

export function SetPageStateSession({ session, locale, countryCode }: Props): JSX.Element | null {
	const viewer = useMemo(() => session && getViewerPageStateData(session), [session]);
	const appState = useMemo(
		() => getAppStatePageStateData({ session, locale, countryCode }),
		[session, locale, countryCode],
	);

	return (
		<>
			{session && <SetPageStateValue name="viewer" value={viewer} persistent />}
			<SetPageStateValue name="appState" value={appState} persistent />
		</>
	);
}
