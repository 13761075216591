import { useLabel } from '../../../../hooks/useLabel';
import { CATEGORIES } from '../../../../shared/consts';
import type { Category, ReportType } from '../../../../shared/types';

type Result = ModelHookResult<
	{
		categories: readonly Category[];
	},
	{
		getLabel: ReturnType<typeof useLabel>['actions']['getLabel'];
	}
>;

export function useCategoryField({ type }: { type: ReportType }): Result {
	const {
		actions: { getLabel },
	} = useLabel(type, 'category');

	return {
		data: {
			categories: CATEGORIES,
		},
		actions: {
			getLabel,
		},
	};
}
