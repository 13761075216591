import { Form } from 'react-final-form';

import { CategoryField } from './components/CategoryField';
import { CountryField } from './components/CountryField';
import { FormError } from './components/FormError';
import { InvalidFormError } from './components/InvalidFormError';
import { QuestionField } from './components/QuestionField';
import { SubCategoryField } from './components/SubCategoryField';
import { SubmitButton } from './components/SubmitButton';
import { SubSubCategoryField } from './components/SubSubCategoryField';
import { usePetitionReportAbuseForm } from './hooks/usePetitionReportAbuseForm';
import type { FormValues, ReportContext } from './shared/types';
import { resetFormDecorator } from './shared/utils/decorators';

type Props = ReportContext & {
	onSuccess?: () => void;
};

export const PetitionReportAbuseForm: React.FC<Props> = ({ onSuccess, ...reportContext }) => {
	const {
		actions: { submitReport },
	} = usePetitionReportAbuseForm({ reportContext, onSuccess });
	const { type } = reportContext;

	return (
		<Form<FormValues> onSubmit={submitReport} decorators={[resetFormDecorator]}>
			{({ handleSubmit }) => (
				<form onSubmit={handleSubmit} data-qa="abuse-report-form">
					<CategoryField type={type} />
					<CountryField />
					<SubCategoryField type={type} />
					<SubSubCategoryField type={type} />
					<QuestionField type={type} />
					<InvalidFormError />
					<FormError />
					<SubmitButton />
				</form>
			)}
		</Form>
	);
};
