import React from 'react';

import { Field } from 'react-final-form';

import { translationKey } from '@change-corgi/core/i18n';
import { useI18n } from '@change-corgi/core/react/i18n';
import { Select } from '@change-corgi/design-system/components/forms';
import { Box } from '@change-corgi/design-system/layout';

import { useFormI18n } from 'src/app/shared/form/hooks';
import { requiredValidator } from 'src/app/shared/form/validators';

import type { FormValues } from '../../shared/types';

import { useCountryField } from './hooks/useCountryField';

const validator = requiredValidator({
	i18n: { key: translationKey('corgi.policy_abuse.generic.errors.country_required') },
});

export const CountryField: React.FC = () => {
	const { translate } = useI18n();
	const { getErrorMessage } = useFormI18n();

	const {
		data: { countries, initialValue },
	} = useCountryField();

	return (
		<Box p={4}>
			<Field<FormValues['country']> name="country" validate={validator} initialValue={initialValue}>
				{({ input, meta }) => (
					<Select
						name={input.name}
						value={input.value}
						onChange={input.onChange}
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						error={meta.touched && getErrorMessage(meta.error)}
						data-testid="reportAbuse-country-select"
						data-qa="reportAbuse-country-select"
						label={translate('corgi.policy_abuse.generic.country.label')}
					>
						<option value="">{translate('corgi.policy_abuse.generic.country.prompt')}</option>
						{countries.map(({ code, name }) => (
							<option value={code} key={code}>
								{name}
							</option>
						))}
					</Select>
				)}
			</Field>
		</Box>
	);
};
