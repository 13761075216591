import type { ComponentProps } from 'react';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { Button } from '@change-corgi/design-system/components/actions';
import { iconNavigateBefore } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';

import { ActionBar } from '../ActionBar';

export const StepActions: React.FC<StepActionsProps> = ({
	disabled,
	loading,
	onSecondaryAction,
	onPrimaryAction,
	...rest
}) => {
	const { translate } = useI18n();
	const showSecondaryActionButton = Boolean(onSecondaryAction);
	const loadingLabel = translate('corgi.petition.starter_onboarding.loading');

	return (
		<ActionBar {...rest}>
			<Flex sx={{ flex: 1, justifyContent: ['flex-start', 'flex-end'] }}>
				{showSecondaryActionButton ? (
					<Box sx={{ ml: [0, 'auto'] }}>
						<Button
							type="button"
							variant="secondary"
							onClick={onSecondaryAction}
							disabled={loading}
							icon={iconNavigateBefore}
							mode={['icon', 'label']}
						>
							<Translate value="corgi.petition.starter_onboarding.components.step_actions.previous_step" />
						</Button>
					</Box>
				) : null}
				<Box sx={{ ml: ['auto', 16] }}>
					<Button
						type="button"
						variant="primary"
						onClick={onPrimaryAction}
						loading={loading}
						loadingLabel={loadingLabel}
						disabled={disabled}
					>
						<Translate value="corgi.petition.starter_onboarding.components.step_actions.next_step" />
					</Button>
				</Box>
			</Flex>
		</ActionBar>
	);
};

type OnClick = ComponentProps<typeof Button>['onClick'];
type StepActionsProps = {
	disabled?: boolean;
	loading?: boolean;
	onSecondaryAction?: OnClick;
	onPrimaryAction: OnClick;
} & ComponentProps<typeof ActionBar>;
