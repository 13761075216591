import { useMemo } from 'react';

import { useFormState } from 'react-final-form';

import type { FieldType, FormValues, Question, ReportType } from '../../../../shared/types';
import { getQuestions, getSubCategoriesOrQuestions, getSubSubCategoriesOrQuestions } from '../../../../shared/utils';

type Result = ModelHookResult<{
	parent: FieldType | undefined;
	questions: readonly Question[];
}>;

export function useQuestionField({ type }: { type: ReportType }): Result {
	const {
		values: { category, subCategory, subSubCategory },
	} = useFormState<FormValues>({
		subscription: { values: true },
	});

	const data = useMemo(() => {
		if (subSubCategory) {
			return {
				parent: subSubCategory,
				questions: getQuestions(type, subSubCategory),
			};
		}

		if (subCategory) {
			const subSubCategoriesOrQuestions = getSubSubCategoriesOrQuestions(type, subCategory);
			return {
				parent: subCategory,
				questions: subSubCategoriesOrQuestions.type === 'question' ? subSubCategoriesOrQuestions.value : [],
			};
		}

		if (category) {
			const subCategoriesOrQuestions = getSubCategoriesOrQuestions(type, category);
			return {
				parent: category,
				questions: subCategoriesOrQuestions.type === 'question' ? subCategoriesOrQuestions.value : [],
			};
		}
		return {
			parent: undefined,
			questions: [],
		};
	}, [category, subCategory, subSubCategory, type]);
	return { data };
}
