import type { IntlCheck } from './types';

async function loadPolyfills(checks: readonly IntlCheck[], locale: string) {
	await Promise.all(
		checks.map(async ({ polyfill, fixNativeIntlFeature }) => {
			const res = await polyfill(locale);
			fixNativeIntlFeature && fixNativeIntlFeature(res);
		}),
	);
}

function checkSingle(check: IntlCheck, locale: string): boolean {
	return (
		check.dependencies.every((innerCheck) => checkSingle(innerCheck, locale)) && check.checkNativeIntlFeature(locale)
	);
}

export async function loadMissingFeatures(checks: readonly IntlCheck[], locale: string): Promise<void> {
	const missingFeatures = checks.filter((check) => !checkSingle(check, locale));

	if (missingFeatures.length) {
		await loadPolyfills(missingFeatures, locale);
	}
}
