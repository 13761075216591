import { useI18n } from '@change-corgi/core/react/i18n';

import type { FieldType, ReportType } from '../../shared/types';

type Result = ModelHookResult<{
	questionLabel: string;
}>;

// For any given Category or SubCategory returns the associated question for the given name
// Using its own function because it needs to support common questions that don't fall under a specific type of parent
export function useQuestionLabel(reportType: ReportType, parent: FieldType, name: string): Result {
	const { translate, translationExists } = useI18n();

	const suffix = `question.${parent}.${name}.text`;
	// If reportType specific translation exists, return it
	if (translationExists(`corgi.policy_abuse.${reportType}.${suffix}`))
		return { data: { questionLabel: translate(`corgi.policy_abuse.${reportType}.${suffix}`) } };

	// If there is no specific translation, return the generic one if it exists
	if (translationExists(`corgi.policy_abuse.generic.${suffix}`))
		return { data: { questionLabel: translate(`corgi.policy_abuse.generic.${suffix}`) } };

	// This does not check for reportType because currently there is no need for that, since the only common question is generic
	// if needed a new check can be added for the reportType
	// If there isn't translation yet, return common translation instead of checking the parent
	return { data: { questionLabel: translate(`corgi.policy_abuse.generic.question.common.${name}.text`) } };
}
