import type { FeatureConfigInfo } from '@change-corgi/core/fcm';

import type { PrefetchContext } from 'src/shared/prefetch';

import { createFcmFunctions } from 'src/app/shared/hooks/fcm';

import type { ExperimentName, FcmExperimentConfig, Result } from './types';
import { getExperimentName } from './types';

export function createFcmExperimentPrefetch<T extends Record<string, FeatureConfigInfo>, EN extends ExperimentName<T>>({
	fcmConfigs,
	isEnabled,
	experimentName,
}: FcmExperimentConfig<T, EN>): (context: PrefetchContext) => Promise<Result<T, EN>> {
	const { prefetchFcm } = createFcmFunctions(fcmConfigs, { prefetch: true });

	return async (context: PrefetchContext): Promise<Result<T, EN>> => {
		const fcm = await prefetchFcm(context);

		const {
			l10n: { locale, countryCode },
		} = context;

		const callbackContext = { fcm, locale, countryCode };

		const getVariation = async (name: string) => {
			const experiment = context.utilityContext.experiments.get(name);
			await experiment.treat();
			return experiment.variation;
		};

		const fcmEnabled = isEnabled(callbackContext);

		const name = getExperimentName(callbackContext, experimentName);

		return {
			fcm,
			name,
			variation: fcmEnabled && name ? await getVariation(name) : 'control',
		};
	};
}
